html{
  font-size: 14px;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}
body{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

// forms
.inline-radio{
  margin: 0 $marginInner;
  display: inline-block;
}
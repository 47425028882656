#events-filters{
  .form-group{


    .form-control--date{
      width: 100px;
    }
    .profile-form-control--date {
      @media screen and (min-width: $media_tablet) {
        float: left;
      }
    }

    .form-control--dropdown {
      width: 140px;
    }
    .fl,
    .fr{
      @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .fr{
      .control-label{
        padding-right: 8px;

        text-align: left;
        @media screen and (min-width: $media_tablet) {
          width: 115px;
          text-align: right;
        }

        @media screen and (max-width: #{$media_tablet - 1px}) {
          text-align: left;
        }
      }
    }
    &.date-holder{
      margin-bottom: 0;
      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }

    }
    &--profile{
      @media screen and (min-width: $media_tablet) {
        max-width: 524px;
      }
    }
  }
}

#eventspage {

  .events-search-table {
  .item {

    @media screen and (min-width: $media_tablet) {
      display: none !important;

      max-width: 130px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;

      &--id {
        width: 50px;
      }
      &--status {
        width: 50px;
      }
      &--eventname {
        max-width: 120px;
      }
      &--associate {
      }
      &--account {
        max-width: 100px;
      }
      &--from {
        width: 50px; ///
      }
      &--to {
        width: 50px;
      }

    }
  }
  }

  .events-status {
    float: left;
    position: relative;
    width: 370px;
    @media screen and (min-width: $media_tablet) {
      width: 370px;
    }
    @media screen and (max-width: #{$media_tablet - 1px}) {
      width: 100%;
    }
  }
}

#event-section {
  .form-group {
    .form-control--select {
      width: 130px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 15px;
    }
    .form-control--dropdown {
      display: inline;
      max-width: none;
      overflow: hidden;
      float: none;
      @media screen and (max-width: $media_maxdesktop) {
        .help-block {
          width: 230px;
          overflow: auto;
          text-overflow: inherit;
          white-space: normal;
        }
      }
    }
    .form-control--dropdown-item {
      width: 130px;
    }
    .control-label--small {
      width: 30px;
      line-height: 34px;
      @media screen and (max-width: $media_mobile) {
        float: none;
      }

      @media screen and (max-width: $media_maxdesktop) {
        display: block;
        float: none;
        line-height: 1.42857;
        margin-bottom: 0;
        font-size: 12px;
      }

    }
    //friday shit
    .control-label--medium {
      width: 65px !important;
    }
    .help-block {
      margin-left: 100px;
    }
  }
}

@import 'assets/normalize';
@import 'assets/_variables';
@import "vendor/bootstrap/bootstrap";
@import 'assets/_mixins';
@import "vendor/awesome/font-awesome";
@import 'assets/redefinition';
@import 'assets/base';
@import 'assets/ui';
@import '_parts/_eventsPage.scss';
//@import '_parts/_invoices.scss';

/*******************************
            base
            header
            navigation
            account
            messages
            main
            breadcrumbs
            footer
            form login
            content block
            profile
            form
            checkboxes
            popup
            reserves count
            admin
*******************************/


/*******************************
    styles for individual pages
*******************************/
@import '_parts/_help.scss';
@import '_parts/_orgMergePage.scss';
@import '_parts/_bookings.scss';
@import '_parts/_preferredRules.scss';
@import '_parts/_fastPay.scss';
@import '_parts/_findBrand';
@import '_parts/_editBrand';
@import '_parts/_reports.scss';
@import '_parts/_payments.scss';
@import '_parts/_fieldset.scss';

[data-target="#modalHelp"] {
  display: none;
}

.high-dropdown {
  height: 50px;
}

.scroll-table {
    overflow: scroll; //
}

th.sortable {
    pointer-events: none;
}

.align-right {
    text-align: right;
}



.history-floating-label {
    @media screen and (min-width: $media_tablet) {
        position: absolute;
        margin-left: -90px;
    }
}

.date-holder {
    @media screen and (min-width: $media_desktop) {
        min-width: 548px;
    }
}

.margin-mobile {
    @media screen and (min-width: $media_tablet) {
        margin-left: 6px;
    }

    @media screen and (min-width: $media_desktop) {
        margin-left: 0px;
    }
}

.fl-mobile {
    @media screen and (max-width: $media_mobile) {
        float: none;
    }

    @media screen and (min-width: $media_tablet) {
        float: left;
    }
}

.fr-mobile {
    @media screen and (max-width: $media_mobile) {
        float: none;
    }

    @media screen and (min-width: $media_tablet) {
        float: right;
    }
}


.margin-mobile-right {
    @media screen and (max-width: $media_mobile) {
        margin-right: 6px;
    }

    @media screen and (min-width: $media_tablet) {
        margin-right: 0px;
    }
}


.pb-booking {
    float:left;
    display: block;
    width:150px;
    text-align: center;
    @media screen and (max-width: $media-mobile) {
        width: 100%;
        padding-top: 20px;
    }
    .progress {
        width: 140px;
        margin: 0 auto 10px;
        height: 5px;
    }
    span {
        display: block;
        width: 100%;
    }
}


.reports-button {
    float: right !important;
    margin-top: -8px;
}

.progress-bar-container {

    .container {
        width: 800px;
        padding-top: 0;
        margin: 0 auto 15px;
        z-index: 100;
    }

    .progressbar {
        padding: 0;
    }

    .progressbar li {
        list-style-type: none;
        width: 14%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        color: #7d7d7d;
        z-index: 20;
        .link-text {
            @media screen and (min-width: $media_mediummobile) {
                display: block;
            }
            margin-top: 10px;
        }
    }

	.progressbar li.selected .link-text{
		font-weight:bold;
	}

    .progressbar-division li {
        width: 16.6%;
    }

    li.selected {
      z-index: 50;
        .link-text {
            display: block;
        }
    }

    li:hover {
        cursor: pointer;
        .pbelement {
            transform: scale(1.1);
        }
    }

    .pbelement {
        display: block;
        width: 24px;
        height: 24px;
        content: ' ';
        line-height: 50px;
        background: #fff;
        text-align: center;
        border-radius: 50%;
        z-index: 100;
        margin: 0 auto;
        transition: all ease .5s;
        border: 5px solid #39afdb;
        cursor: pointer;
        transform-origin: 50% 50%;
        &.passed {
            background: #39afdb;
        }
        &.error {

        }
    }

    .progressbar li:not(:first-child):before, .progressbar li:not(:last-child):after {
        z-index: -1;
        width: 50%;
        height: 4px;
        content: '';
        position: absolute;
        background-color: #f3f3f3;
        top: 10px;
    }


    .progressbar li:before {
      left: 0;
    }

    .progressbar li:after {
      right: 0;
    }

    .link-text {
      @media screen and (max-width: $media_mediummobile) {
        display: none;
      }
    }
}



/*******************************
            base
*******************************/
.only-content {
    .js-messages-sidebar {
        display: none !important;
        visibility: hidden;
        width: 0px !important;
    }

    .main {
        width: calc(100% - 20px*2);
        border-left: 0px !important;
        border-right: 0px !important;
        padding-top: 0;
    }

    .main-nav_list--general {
        display: none;
    }
}


html {
    height: 100%;
}

body {
    height: 100%;
    background: $c_gray_base;
    padding-right: 0 !important;
}

.site-wrapper {
    min-height: 100%;

    @media screen and (min-width: $media_tablet) {
        position: relative;
    }
}

.main-wrapper {
    padding-bottom: $headerHeight;
    position: relative;

    @include transition();
}

/*******************************
            header
*******************************/

.site-header {
    height: $headerHeight;
    background: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid $c_gray_light;
    position: relative;
    z-index: 210;
    //z-index: 1500;///
    transition: all 0.5s ease;

    @media screen and (max-width: $media_mobile) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
    // logo
    &_logo {

        &-link {
            @include centered('y');
            left: $margin;
            transition: all .1s ease;

            @media screen and (max-width: $media_mobile) {
                right: $margin;
                left: auto;
            }
        }
    }
}

/*******************************
            navigation
*******************************/


.main-nav {
    @media screen and (max-width: $media_smallmobile) {
        width: 80vw;
    }

    @media screen and (max-width: $media_mobile) {
        display: none;
        position: absolute;
        top: 0;
        left: -500px;
        width: 85vw;
        background: $c_blue_brand;
        box-sizing: border-box;
        height: 100%;
        height: 100vh;
        overflow-y: auto;
        z-index: 100;
        @include box-shadow(1px, 4px, 4px, rgba(0, 0, 0, 0.5));
        @include transition();
    }

    .iphone.safari & {
        padding-bottom: 55px;
    }

    &_list {
        @extend %reset;
        position: absolute;
        left: 0;
        top: 0;
        width: $menuWidth;
        background: $c_blue_brand;
        color: #fff;
        z-index: 1;
        padding-top: $headerHeight + $margin + $marginInner;
        font-size: 0.9rem;

        @media screen and (max-width: $media_mobile) {
            position: relative;
            min-height: 0;
            left: auto;
            top: auto;
            width: 100%;
            padding: 0;
        }

        &--general {
            @media screen and (min-width: $media_tablet) {
                min-height: 100%;
            }
        }

        &--account {
            display: none;
            position: absolute;
            right: 0;
            top: $headerHeight;
            min-height: 0;
            left: auto;
            width: $menuAccountWidth;
            padding: 0;
            z-index: 20;
            overflow: hidden;

            @media screen and (min-width: $media_tablet) {
                border-radius: 0 0 0 $radius;
            }

            &.js-active {
                display: block;
                z-index: 1002;
            }

            @media screen and (max-width: $media_mobile) {
                display: block;
                position: relative;
                min-height: 0;
                left: auto;
                top: auto;
                width: 100%;
                padding: 0;
                margin-bottom: $marginInner*2;
                overflow: visible;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -$marginInner;
                    width: 100%;
                    height: 1px;
                    background: #e0e0e0;
                    border-left: solid $c_blue_brand;
                    border-right: solid $c_blue_brand;
                }
            }
        }

        &--logout {
            display: none;

            @media screen and (max-width: $media_mobile) {
                display: block;
                margin-top: $marginInner*2;
                margin-bottom: $margin;

                &:before {
                    content: '';
                    position: absolute;
                    top: -$marginInner;
                    width: 100%;
                    height: 1px;
                    background: #e0e0e0;
                    border-left: solid $c_blue_brand;
                    border-right: solid $c_blue_brand;
                }
            }
        }

        &-item {
            display: block;
            text-align: center;
            margin-bottom: $margin;

            @media screen and (max-width: $media_mobile) {
                text-align: left;
                white-space: nowrap;
                margin: 0;
            }

            &--messages {
                @media screen and (min-width: $media_mediumdesktop) {
                    display: none;
                }
            }

            &--account {
                text-align: left;
                margin: 0;

                &:first-of-type {
                    .main-nav_list-item_link {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &--logout,
            &--mycontacts {
                @media screen and (max-width: $media_mobile) {
                    display: none;
                }
            }

            &_link {
                color: #fff;
                text-decoration: none;
                display: block;
                cursor: pointer;

                @media screen and (max-width: $media_mobile) {
                    height: 45px;
                    line-height: 45px;
                    padding: 0 $margin;
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    color: darken($c_blue_light, 5%);

                    @media screen and (max-width: $media_mobile) {
                        color: #fff;
                        background: $c_blue_hover;
                    }
                }

                &-icon {
                    font-size: 2rem;
                    display: block;
                    margin-bottom: $radius;

                    @media screen and (max-width: $media_mobile) {
                        display: inline-block;
                        margin-right: $marginInner;
                        vertical-align: sub;
                        width: 25px;
                    }
                }

                &-text {
                    display: block;

                    @media screen and (max-width: $media_mobile) {
                        display: inline-block;
                        font-size: 1.2rem;
                    }
                }

                &--account {
                    white-space: nowrap;
                    padding: 0 $marginInner 0 $margin*2;
                    position: relative;

                    @media screen and (max-width: $media_mobile) {
                        height: 45px;
                        line-height: 45px;
                        padding: 0 $margin;
                    }

                    &:after {
                        content: '';
                        @include centered('x');
                        right: 0;
                        height: 1px;
                        width: 135px;
                        background: #fff;

                        @media screen and (max-width: $media_mobile) {
                            display: none;
                        }
                    }

                    .main-nav_list-item_link {

                        &-icon {
                            font-size: 1rem;
                            margin: -2px 0 0 0;
                            @include centered('y');
                            left: $marginInner;

                            @media screen and (max-width: $media_mobile) {
                                font-size: 2rem;
                                display: inline-block;
                                margin: 0 $marginInner 0 0;
                                vertical-align: sub;
                                position: relative;
                                left: auto;
                                @include centered('0');
                            }
                        }

                        &-text {
                            display: inline-block;
                            vertical-align: middle;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
    }
}

.menu-btn {
    @extend %reset;
    display: none;
    @include centered('y');
    font-size: 2.5rem;

    @include transition();

    @media screen and (max-width: $media_mobile) {
        display: block;
        left: $margin;
    }
}

// open menu for mobile
.js-menu-opened {
    @media screen and (max-width: $media_smallmobile) {
        .main-wrapper,
        .site-footer,
        .site-header {
            left: 80vw;
        }
    }

    @media screen and (max-width: $media_mobile) {
        overflow: hidden;
        position: fixed;

        .main-nav {
            display: block;
            left: 0;
        }

        .site-header_logo-link {
            display: none;
        }

        .main-wrapper,
        .site-header,
        .site-header {
            left: 85vw;
        }
    }
}

.js-sidebar-opened {
    overflow: hidden;
    position: fixed;
}

.js-modal-opened,
.modal-open {
    overflow: hidden !important;
    position: fixed;
    width: 100%;

    @media screen and (min-width: $media_tablet) {
        position: relative;
    }

    .modal {
        overflow-y: auto;
    }
}

/*******************************
            account
*******************************/

.hide-mobile {
    @media screen and (max-width: $media_mobile) {
        display: none;
    }
}

.only-mobile {
    display: none;

    @media screen and (max-width: $media_mobile) {
        display: inline-block;
    }
}

.account {
    &--header {
        right: $margin;
        cursor: pointer;
        height: 75px;
        position: absolute;
        padding-top: 14px;

        @media screen and (max-width: $media_mobile) {
            left: $margin;
            right: auto;
            display: none;
        }

        .avatar-head {
            width: 48px;
            height: 48px;
            position: relative;
            @include border-radius(50%);
            overflow: hidden;
            vertical-align: middle;
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid $c_gray_light;
            margin: 0 $marginInner;
            text-align: center;

            img {
                //height: auto;
                //max-width: 100%;
                //min-height: 100%;
                //min-width: 100%;
                //left: 50%;
                //position: relative;
                //-ms-transform: translate(-50%, 0);
                //-webkit-transform: translate(-50%, 0);
                //transform: translate(-50%, 0);
                background-color: #d8d8d8;
                height: auto;
                max-width: 100%;
                min-height: 100%;
                min-width: 100%;
                left: 50%;
                position: relative;
                -ms-transform: translate(-50%,0);
                -webkit-transform: translate(-50%,0);
                transform: translate(-50%, 0);
            }
        }
    }

    &--main-nav {
        color: #fff;
        margin: $margin;
        display: none;

        @media screen and (max-width: $media_mobile) {
            display: block;
        }

        .avatar {
            margin: 0 15px 0 0;
        }

        .avatar-head {
            width: 48px;
            height: 48px;
            position: relative;
            @include border-radius(50%);
            overflow: hidden;
            vertical-align: middle;
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid $c_gray_light;
            margin: 0 $marginInner;
            text-align: center;

            img {
                height: 100%;
                left: 50%;
                max-width: none;
                min-height: 100%;
                min-width: 100%;
                position: relative;
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
            }
        }
    }

    &_name {
        display: inline-block;
    }

    .account-name--main-nav {
        font-size: 20px;
    }

    &_btn {
        @extend %reset;
    }
}

/*******************************
            messages
*******************************/
.messages {
    &-sidebar {
        box-sizing: border-box;
        background: #fff;
        border: 1px solid $c_gray_light;
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        min-height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1041;
        overflow-x: hidden;
        overflow-y: auto;
        @include transition();

        @media screen and (min-width: $media_tablet) {
            width: 0;
            height: auto;
            border-radius: $radius;
            min-height: 500px;
            position: absolute;
            overflow: hidden;
            left: auto;
            right: -300px;
            top: $margin + $marginInner;
            margin-right: 20px;
            bottom: auto;
        }

        @media screen and (min-width: $media_mediumdesktop) {
            width: $sidebarWidth;
            float: right;
            display: block !important;
            position: fixed;
            right: 0;
            top: 75px;
            bottom: auto;
            z-index: 10;
            margin-top: 33px;
            min-height: 500px;
            @media screen and (max-width: 1280px) {
                width: $sidebarWidth - 40px;
            }
        }


        &--fixed {
            @media screen and (min-width: $media_mediumdesktop) {
                top: 0;
            }
        }

        &.showing {
            display: block;
            right: 0;
            width: 100%;

            @media screen and (min-width: $media_tablet) {
                width: $sidebarWidth;
                position: fixed;
            }
        }

        &_header {
            background: $c_blue;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 35px;
            position: relative;
        }

        &-close {
            position: absolute;
            right: 15px;
            color: #fff;
            cursor: pointer;
            font-size: 22px;
            top: -1px;

            @media screen and (min-width: $media_mediumdesktop) {
                display: none;
            }
        }

        &_body {
            padding: $marginInner;
            position: relative;
            height: 100%;

            .form-control {
                margin: 3px 0 $marginInner;
            }

            .field-with-datepicker .form-control {
                margin: 0;
            }

            .form-group {
                margin: $marginInner 0 0;
                text-align: center;
            }

            .select-out {
                position: relative;
            }

            textarea {
                height: 60px;
                resize: none;
            }

            select {
                padding-right: 20px;
                -webkit-appearance: none;
                -moz-appearance: none;
            }

            select::-ms-expand {
                display: none;
            }

            .form-control-caret {
                position: absolute;
                right: 8px;
                top: 10px;
            }
        }

        &_caption {
            margin-top: 5px;
            font-size: 9px;
        }
    }

    &-link {
        color: #57a3f0;
        cursor: pointer;
    }
    //messages area
    &-area-out {
        margin: 10px -15px 20px;

        .modal-loader {
            font-size: 12px;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
        }
    }

    &-area {
        overflow-y: auto;

        @media screen and (min-width: $media_tablet) {
            max-height: 310px;
        }
    }

    &-notification {
        background-color: #1d344f;
        color: #fff;
        font-size: 12px;
        width: 100%;
        padding: 5px 10px;
    }

    &-title {
        color: #979797;
        font-size: 12px;
        margin: 10px 15px 0;
        text-align: center;
        position: relative;

        &:before {
            border-top: 1px solid #979797;
            content: "";
            height: 1px;
            position: absolute;
            width: 100%;
            left: 0;
            right: 15px;
            @include centered('y');
        }

        &-txt {
            display: inline-block;
            padding: 0 10px;
            background-color: #fff;
            position: relative;
            z-index: 2;
        }
    }

    &-avatar {
        border: 1px solid #d8d8d8;
        border-radius: 50%;
        display: inline-block;
        height: 23px;
        margin: 0 5px 0 15px;
        overflow: hidden;
        vertical-align: middle;
        width: 23px;
        float: left;
        font-size: 0;
        line-height: 0;

        img {
            //height: auto;
            //max-width: 100%;
            //min-height: 100%;
            //min-width: 100%;
            //left: 50%;
            //position: relative;
            //-ms-transform: translate(-50%, 0);
            //-webkit-transform: translate(-50%, 0);
            //transform: translate(-50%, 0);
            height: 100%;
            max-width: none;
            min-height: 100%;
            min-width: 100%;
            position: relative;
        }
    }

    &-content {
        overflow: hidden;
        margin-right: 15px;
    }

    &-name {
        color: #57a3f0;
        overflow: hidden;
        padding-right: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-time {
        color: #d3d4d7;
        font-size: 12px;
        width: 60px;
        float: right;
        padding-top: 2px;
        text-align: right;
        line-height: 20px;
    }

    &-body {
        color: #000;
        font-size: 12px;
        clear: both;
        position: relative;
        max-height: 38px;
        display: block;
        overflow: hidden;
        margin-left: 15px;
        padding-right: 15px;
        padding-top: 3px;

        &:after {
            display: none;
            content: "...";
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 0 0 1px 10px;
            background-color: #fff;
        }
    }

    &-apply {
        text-align: right;
        float: right;

        &-button {
            background-color: transparent;
            border: 0;
            color: #1d344f;
            font-size: 14px;
            line-height: 20px;
        }

        &-delete {
            display: none;
        }
    }

    &-item {
        padding: 10px 0;
        cursor: pointer;

        &:hover,
        &.select {
            background-color: #ebf3fe;

            .messages-time {
                color: #4a4a4a;
                /*display: none;*/
            }

            .messages-apply-delete {
                display: inline-block;
            }

            .messages-body:after {
                background-color: #ebf3fe;
            }
        }
    }
}

/*******************************
            main
*******************************/

.main {
    position: relative;
    margin: 0 auto;
    padding-top: $margin+$marginInner;
    border-left: $menuWidth + 50 solid transparent;
    min-height: 565px; // main menu height

    @media screen and (max-width: $media_mobile) {
        width: calc(100% - #{$margin}*2);
        margin: $headerHeight + $margin auto 0;
        border: 0;
    }

    @media screen and (max-width: 1279px) {
        border-left-width: 120px;
    }

    @media screen and (min-width: $media_tablet) {
        padding-right: 20px;
    }

    @media screen and (min-width: $media_mediumdesktop) {
        border-right: $sidebarWidth + $margin*2 solid transparent;
        padding-right: 0;
        @media screen and (max-width: 1280px) {
            border-right-width: $sidebarWidth + $margin*2 - 40px;
        }
    }

    &--no-sidebar {
        border-left: 0;
        border-right: 0;
        width: 100%;
    }

    &--no-messagebar {
      @media screen and (min-width: $media_mediumdesktop) {
        border-right: 20px;
        padding-right: 20px;
      }
    }
}

/*******************************
         breadcrumbs
*******************************/

.breadcrumbs {
    overflow: hidden;
    border-radius: $radius;
    background: lighten($c_gray_light, 7%);
    height: 35px;
    line-height: 35px;
    box-sizing: border-box;
    padding: 0 $marginInner;

    &_link {
        display: inline-block;
        vertical-align: middle;

        &:after {
            content: '/';
            color: $c_gray;
            display: inline-block;
            vertical-align: middle;
            margin: 0 $radius;
        }
    }

    &_text {
        color: #000;
    }
}


/*******************************
            footer
*******************************/

.site-footer {
    height: $headerHeight;
    margin-top: -$headerHeight;
    box-sizing: border-box;
    padding: 0;
    z-index: 2;
    @include transition();

    &--no-sidebar {
        padding: 0;
    }

    @media screen and (max-width: $media_mobile) {
        padding: 0;
    }
}

.copyright {
    width: 100%;
    text-align: center;
    padding: 0 $marginInner;

    &-holder {
        position: relative;
        height: 100%;
        padding-top: 30px;
    }
}

.container.copyright-holder {
    @media screen and (max-width: $media_mobile) {
        width: 100%;
    }
}




/*******************************
            form login
*******************************/
.form-login {
    background-color: #fff;
    width: 348px;
    margin: 50px auto 0;
    padding: 30px 24px;
    @include border-radius(8px);
    @include centered(x);

    @media screen and (min-width: $media_tablet) {
        @include centered(xy);
        margin: 0 auto;
    }

    .input-group-addon {
        background-color: #1d344f;
        color: #fff;
        border-color: #1d344f;
    }

    .input-group {
        padding-bottom: 15px;

        &--link {
            text-align: right;
            width: 100%;
            padding-top: 15px;
            padding-bottom: 0;
        }
    }



    .form-control {
        .has-error & {
            border-color: #d01d1b;
        }
    }

    .title-login {
        color: #1e344f;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        padding-bottom: 15px;
    }


    .btn-success {
        background-color: #7ed321;
        border-color: #64a81a;
        @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0) inset, 0 0 0 rgba(0, 0, 0, 0));

        &:hover {
            opacity: 0.7;
        }

        &[disabled],
        &[disabled]:hover {
            cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
        }
    }

    .error-message {
        color: #d01d1b;
        font-size: 13px;
        text-align: center;
        padding-bottom: 10px;
    }

    .caption-form {
        font-size: 13px;
        color: #494949;
        padding-top: 15px;
    }

    .link-form-login {
        border-bottom: 1px solid #4a90e3;

        &:hover {
            text-decoration: none;
            border-bottom: 0;
        }
    }
}

//Success
.success {
    &-form-login {
        text-align: center;
    }

    &-caption {
        font-size: 13px;
        color: #1a1a1a;
        padding-top: 14px;
    }

    &-icon {
        color: #427505;
        font-size: 54px;
        line-height: 54px;
        margin-bottom: 30px;

        &--warning {
            color: #ff7e00;
            font-size: 54px;
            line-height: 54px;
            margin-bottom: 30px;
        }
    }

    &-subtitle {
        color: #26344f;
    }
}

/*******************************
            content block
*******************************/
.content {
    &-title {
        color: #000000;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 20px;

        &--center {
            text-align: center;
            padding-bottom: 30px;
        }
    }

    &-block {
        background-color: #fff;
        padding: $paddingInner;
        margin-top: $margin;
        @include border-radius(4px);

        @media screen and (min-width: $media_tablet) {
            @include box-shadow(0, 1px, 0, rgba(0, 0, 0, 0.18));
        }

        .modal-body & {
            padding: 0;
            margin-top: 0;
            @include border-radius(0);
            @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
        }

        &--tabs {
            @media screen and (min-width: $media_tablet) {
                margin-top: 0;
                @include border-radius(0 0 4px 4px);
            }
        }

        &--contacts {
            padding: 0 0 $paddingInner;
        }

        .tab-footer {
            border-top: 4px solid #f3f3f3;
            margin: 30px -20px 0;
            padding: $paddingInner;

            &--tall {
                margin-top: 75px;
            }
        }

        .footer-section-tall {
            margin-bottom: 70px;
        }
    }
}

.block {
    &--two-col {
        @include box-sizing();
        width: 100%;

        @media screen and (min-width: $media_mediummobile) {
            width: 50%;
        }
    }
}

/*******************************
            profile
*******************************/
.profile {
    &-form {
        &--my-contacts {
            max-width: 356px;

            @media screen and (min-width: $media_tablet) {
                max-width: none;
            }
        }
    }

    &-photo {
        width: 116px;
        height: 116px;
        background-color: #d8d8d8;
        float: left;
        position: relative;
        overflow: hidden;
        @include border-radius(4px);

        @media screen and (min-width: $media_desktop) {
            width: 85px;
            height: 85px;
        }

        img {
            //height: 100%;
            //max-width: 100%;
            height: auto;
            //max-height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
            //height: 100%;
            left: 50%;
            //max-width: none;
            //min-height: 100%;
            //min-width: 100%;
            position: relative;
            //margin-left: -1px;
            //transform: translate(-50%, 0px);
            -ms-transform: translate(-50%, 0);
            -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
    }

    &-icon {
        bottom: 0;
        color: #fff;
        font-size: 80px;
        line-height: 60px;
        @include centered('x');

        @media screen and (min-width: $media_smalldesktop) {
            font-size: 120px;
            line-height: 80px;
        }
    }

    &-edit-button {
        bottom: 5px;
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        right: 6px;
        z-index: 10;
        color: #4a4a4a;

        &:hover {
            opacity: .8;
        }
    }

    &-remove-button {
        bottom: 5px;
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        left: 6px;
        z-index: 10;
        color: #4a4a4a;

        &:hover {
            opacity: .8;
        }
    }

    &-edit-button-left {
        bottom: 5px;
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        left: 5px;
        z-index: 10;
        color: #4a4a4a;

        &:hover {
            opacity: .8;
        }
    }

    &-edit-button-left {
        bottom: 5px;
        cursor: pointer;
        font-size: 18px;
        position: absolute;
        left: 5px;
        z-index: 10;
        color: #4a4a4a;

        &:hover {
            opacity: .8;
        }
    }

    input[type="file"] {
        display: none;
    }

    &-user-name {
        overflow: hidden;
        padding-left: $paddingInner;
    }

    &-tabs {
        margin-top: $margin;
    }

    &-nav-tabs {
        border-bottom: 0;

        .dropdown-toggle--profile {
            border: none;
            background: #fff;
            margin-right: 0;
            color: #000;
            @include border-radius(4px);

            @media screen and (min-width: $media_tablet) {
                display: none;
            }

            .caret--profile {
                float: right;
                color: #000;
                margin-top: 8px;
            }
        }

        .dropdown-menu--profile {
            padding: 0;
            width: 100%;
            background: #fff;
            z-index: 1000; //
            @media screen and (min-width: $media_tablet) {
                position: relative;
                display: block;
                border: none;
                padding: 0;
                background: transparent;
                @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
            }

            .nav-tabs-tab {
                @media screen and (min-width: $media_tablet) {
                    float: left;
                }
            }

            .tabs-link {
                @media screen and (min-width: $media_tablet) {
                    height: 40px;
                    line-height: 38px;
                    font-size: 12px;
                    padding: 3px 10px;
                }

                @media screen and (min-width: $media_desktop) {
                    font-size: 14px;
                    padding: 3px 20px;
                }
            }
        }

        .dropdown-menu--profile > .active > a,
        .dropdown-menu--profile > .active > a:hover,
        .dropdown-menu--profile > .active > a:focus {
            background-color: #a8a8a8;

            @media screen and (min-width: $media_tablet) {
                background: #fff;
                color: #424a54;
            }
        }

        .dropdown--profile {
            float: none;
            width: 100%;

            &.active .dropdown-toggle--profile {
                cursor: pointer;
                border: 0;
            }
        }
    }

    .nav-tabs-tab {
        &.active {
            border-bottom: 0;

            .tabs-link {
                border-bottom: 0;

                &:hover,
                &:focus {
                    border-bottom: 0;
                }
            }
        }
    }

    .tabs-link {
        color: #424a54;
        cursor: pointer;
        background-color: #e6e9ed;
        margin-right: 0;
        margin-left: 0;
        border-right: 1px solid #ccd1d9;
        border-bottom: 0;
        @include border-radius(0);

        @media screen and (max-width: $media_mobile) {
            background-color: #fff;
            padding: 10px 15px;
        }

        &:hover,
        &:focus {
            border-bottom: 0;
            border-right: 1px solid #ccd1d9;
        }

        .active & {
            border-bottom: 0;
        }
    }

    .btn-input-tabs {
        width: 34px;
        height: 34px;
        padding: 0;
        line-height: 34px;
        text-align: center;
        color: #a0a0a0;
        border: 1px solid #dadada;
        background-color: #fff;
        margin-left: 5px;
        float: right;
        position: relative;
        z-index: 100;

        &--error {
            border-color: #d01d1b;
        }

        &--no-float {
            float: none;
        }

        &--no-margin {
            margin-left: 0;
        }

        &:hover {
            background-color: #dadada;
        }

        &:focus,
        &:active {
            outline: none;
        }

        &.disabled:hover,
        &.disabled:focus {
            background-color: transparent;
        }

        &--affiliation {
            background-color: #c9c9c9;
            color: #fff;
        }

        &--absolute {
            bottom: 0;
            position: absolute;
            right: -40px;
        }

        &--mobile-right {
            @media screen and (max-width: #{$media_tablet - 1px}) {
                position: absolute;
                right: 0;
            }
        }
    }

    .control-label {
        color: #000;
        display: block;
        margin-bottom: 0;
        font-size: 10px;

        @media screen and (min-width: $media_tablet) {
            width: 115px;
            float: left;
            height: 34px;
            line-height: 34px;
            font-size: 14px;
            position: relative;
            margin-top: 0;
        }

        &--middle {
            width: 115px;
        }

        &--larger {
            width: 125px;
        }

        &--big {
            width: 140px !important;
        }

        &--xbig {
            width: 160px !important;
        }

        &--verybig {
            width: 174px;
        }

        @media screen and (min-width: $media_desktop) {
            display: block;
        }

        &--visible {
            @media screen and (max-width: $media_mobile) {
                display: block;
            }
        }

        &--long {
            width: auto;
            padding-right: 10px;
        }

        &--affiliation {
            width: 100%;
            display: block;
            float: none;

            @media screen and (min-width: $media_mediummobile) {
                width: 145px;
                float: left;
            }
        }

        &--r {
            @media screen and (min-width: $media_desktop) {
                text-align: right;
                padding-right: 20px;
            }
        }

        &--center {
            text-align: center;
        }

        &--no-width {
            width: auto;
            padding-right: 10px;
        }

        &--history-date {
            @media screen and (min-width: $media_tablet) {
                width: 100%;
            }
            @media screen and (min-width: $media_desktop) {
                width: 180px;
            }
        }

    }

    .reg {
        color: #9b9b9b;
    }

    .form-group {
        &--profile {
            width: 100%;
            position: relative;

            @media screen and (min-width: $media_tablet) {
                max-width: 445px;
            }

            @media screen and (min-width: $media_desktop) {
                max-width: 445px;
            }

            &-small {
                max-width: 355px;
            }

            &-big {
                max-width: 540px;
            }

            &-verybig {
                max-width: 710px;
            }

            &.last {
                margin-bottom: 0;
            }

            &-full {
                max-width: 445px;

                @media screen and (min-width: $media_tablet) {
                    max-width: none;
                    padding-right: 334px;
                }
            }
        }

        &--not-full {
            max-width: 445px;
        }

        &--affiliation {
            margin-bottom: 5px;
            position: relative;
        }

        .select-out {
            position: relative;
        }

        &--last {
            margin-bottom: 0;
        }
    }


    &-form-control {
        overflow: hidden;

        .form-group--foreach:last-child {
            margin-bottom: 0;
        }



        &--full {
            .profile-form--trim & {
                @media screen and (min-width: $media_tablet) {
                    max-width: none;
                }
            }
        }

        .fl {
            float: left;
        }

        .fr {
            float: right;
        }

        &--trim {
            @media screen and (min-width: $media_tablet) {
                padding-right: 39px;
            }
        }

        select {
            padding-right: 20px;
            -webkit-appearance: none;
            -moz-appearance: none;
        }

        select::-ms-expand {
            display: none;
        }
    }

    .form-control {
        &--date {
            width: 90px;
        }

        &--date-medium {
            width: 141px;
        }

        &--overflow-text {
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 15px;
        }
    }

    &-form-data {
        line-height: 34px;
        overflow: hidden;
        color: #4a4a4a;

        &--float-rigth {
            @media screen and (min-width: $media_tablet) {
                float: right;
            }
        }
        &-text {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .btn-primary--tabs {
        width: auto;
        margin-left: 3px;
    }

    .profile-apply-group {
        margin-bottom: 15px;
        position: relative;
        max-width: 445px;

        &--organization {
            @media screen and (min-width: $media_tablet) {
                max-width: none;
                margin-bottom: $margin;
                margin-right: 334px;
                position: relative;
                min-height: 300px;
            }

            @media screen and (min-width: $media_desktop) {
                min-height: 230px;
            }
        }

        &--team-tab {
            @media screen and (min-width: $media_tablet) {
                max-width: none;
                margin-bottom: 0;
                margin-right: 334px;
                position: relative;
                min-height: 0;
            }

            @media screen and (min-width: $media_desktop) {
                min-height: 0;
            }
        }

        .apply-name {
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 85%;
            color: #000;

            @media screen and (min-width: $media_smallmobile) {
                width: 90%;
            }

            @media screen and (min-width: $media_tablet) {
                width: 100%;
            }

            &:hover {
                text-decoration: none;
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        .profile-apply {
            width: 100%;
            padding: 10px 15px;
            border: 1px solid #e6e6e6;
            cursor: pointer;
            margin-top: -1px;

            &--organization {
                padding: 0;

                .apply-name {
                    display: inline-block;
                    padding: 10px 15px;
                    text-decoration: none;
                    width: 80%;

                    @media screen and (min-width: $media_smallmobile) {
                        width: 88%;
                    }

                    @media screen and (min-width: $media_tablet) {
                        width: 100%;
                        display: block;
                    }
                }

                .apply-button--single {
                    margin-right: 15px;
                    margin-top: 10px;
                    position: absolute;
                    right: 0;
                }

                .link-accordion-head {
                    display: none;
                }

                &.selected {
                    .text-accordion-head {
                        display: none;
                    }

                    .link-accordion-head {
                        display: inline-block;
                    }
                }
            }

            &.selected,
            .active & {
                background-color: #e6e6e6;

                .apply-button--single {
                    @media screen and (max-width: $media_mobile) {
                        display: inline-block;
                    }
                }
            }

            &:first-child {
                border-width: 1px;
            }

            &.disabled {
                cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
            }
        }
    }

    .form.profile-form .organization-group:last-child .profile-apply-group--team-tab {
        @media screen and (min-width: $media_tablet) {
            min-height: 230px;
            margin-bottom: $margin;
        }

        @media screen and (min-width: $media_desktop) {
            min-height: 130px;
        }
    }

    .organization-group {
        &.panel-group .panel + .panel {
            margin-top: 0;
        }

        .panel {
            border: 0;
            border-radius: 0;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            margin-bottom: 0;

            .profile-apply {
                border-width: 1px 1px 0 1px;
            }

            &-collapse {
                border-color: #e6e6e6;
                border-style: solid;
                border-width: 1px 1px 0 1px;

                @media screen and (min-width: $media_tablet) {
                    border-width: 0;
                }
            }

            &:last-child {
                .profile-apply {
                    border-width: 1px;
                }

                .panel-collapse {
                    border-width: 0 1px 1px;

                    @media screen and (min-width: $media_tablet) {
                        border-width: 0;
                    }
                }
            }
        }
    }

    .affiliation {
        padding: $paddingInner;

        @media screen and (min-width: $media_tablet) {
            border: 1px solid #dadada;
            background-color: #f6f6f6;
        }

        &-panel-collapse {
            @media screen and (min-width: $media_tablet) {
                position: absolute;
                top: 0;
                left: 100%;
                width: 314px;
                margin-left: $margin;
            }
        }

        &-title {
            color: #000;
            text-transform: uppercase;
            font-weight: bold;
            padding-bottom: 15px;
        }

        .form-control {
            padding: 6px;
        }
    }

    .form-control-caret {
        position: absolute;
        right: 8px;
        top: 10px;
    }

    .apply-button {
        &--general {
            display: none;
            background-color: #fff;
            border: 1px solid #a9b2bd;
            height: 34px;
            margin: 4px 0 0 15px;
            padding: 0 10px;
            position: absolute;
            left: 100%;
            top: 0;
            white-space: nowrap;
            @include border-radius(4px);

            &.not-active,
            &[disabled] {
                opacity: .8;
                cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
            }

            @media screen and (min-width: $media_tablet) {
                display: block;
            }

            &:hover {
                opacity: .8;
                background-position: 0;
            }
        }

        &--affiliation {
            position: relative;
            left: auto;
            top: auto;
            margin: $margin 0 0 0;
        }

        &--single {
            background-color: transparent;
            border: 0;
            float: right;
            display: none;

            @media screen and (min-width: $media_tablet) {
                width: 0;
                height: 0;
                font-size: 0;
            }
        }

        .fa-trash {
            color: #a0a0a0;
        }

        .fa-minus {
            display: none;

            @media screen and (min-width: $media_tablet) {
                display: inline-block;
            }
        }

        .apply-button-label {
            display: none;

            @media screen and (min-width: $media_tablet) {
                font-size: 14px;
                margin-left: 10px;
                display: inline-block;
            }
        }
    }

    .intl-tel-input {
        display: block;
        position: static;

        .country-list {
            z-index: 1001;
        }

        .flag-container {
            bottom: auto;
            top: auto;
        }

        .selected-flag {
            height: 34px;
        }
    }
}

/*******************************
            form
*******************************/
.btn-booking-options {
    @media screen and (max-width: $media_mobile) {
        margin-top: 5px;
        float:left;
    }
}

.booking-btn-panel {
    @media screen and (max-width: $media_mobile) {
        display:block;
    }
}

.form {
    &--admin {
        margin: 0 -10px;
    }

    &-table {
        display: table;
        width: 100%;

        .search-contact & {
            //@media screen and (min-width: $media_mediummobile) {
            //    max-width: 356px;
            //}
            //
            //@media screen and (min-width: $media_desktop) {
            //    max-width: none;
            //}
        }

        &--two-col {
            @media screen and (min-width: $media_tablet) {
                width: 50%;
                float: left;
                padding: 0 10px;
            }
            &.align-right {
                @media screen and (max-width: $media_tablet) {
                    text-align: left;
                }
            }
            &.form-table--two-col-fixed {
                @media screen and (min-width: $media_tablet) {
                    width: 390px;
                }
                .form-group--history-date {
                    @media screen and (min-width: $media_tablet) {
                        float: left;
                        clear: left;
                    }
                }
                .form-group--history-date {
                    @media screen and (min-width: $media_tablet) {
                        width: 100%;
                    }
                }
                .control-label {
                    @media screen and (min-width: $media_tablet) {
                        width: 150px;
                    }
                }
                .profile-form-data {
                    @media screen and (min-width: $media_tablet) {
                        width: calc(100% - 150px);
                    }
                }
            }
        }

        &--tree-col {
            @media screen and (min-width: $media_tablet) {
                width: 33.3%;
                float: left;
                padding: 0 10px;
            }
        }

        &--middle {
            @media screen and (min-width: $media_tablet) {
                width: 33%;
                float: left;
            }

            @media screen and (min-width: $media_desktop) {
                width: 40%;
                float: left;
            }
        }

        &--small {
            @media screen and (min-width: $media_tablet) {
                width: 30%;
                float: left;
            }

            @media screen and (min-width: $media_desktop) {
                width: 20%;
                float: left;
            }
        }

        &--center {
            @media screen and (min-width: $media_tablet) {
                padding: 0 6%;
            }

            @media screen and (min-width: $media_desktop) {
                padding: 0 6% 0 0;
            }
        }
    }

    &-row {
        display: table-row;
        margin-bottom: $marginInner;
    }

    &-cell {
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 15px;

        &--label {
            display: none;

            @media screen and (min-width: $media_desktop) {
                padding-right: $paddingInner;
                white-space: nowrap;
                display: table-cell;
            }

            .reg {
                color: #9b9b9b;
            }
        }

        &--select {
            position: relative;
            width: 100%;
        }

        &--control {
            width: 100%;

            @media screen and (min-width: $media_tablet) {
                .form-control {
                    max-width: 330px;
                }
            }
        }

        &--account-select {
            padding-left: 5px;
            position: relative;

            select {
                width: 123px;
            }
        }

        &--account-control {
            .form-control {
                @media screen and (min-width: $media_tablet) {
                    width: 348px;
                }
            }
        }
    }

    &-caption {
        width: 100%;
        color: #9b9b9b;

        @media screen and (max-width: $media_mobile) {
            display: none;

            &--visible {
                display: block;
            }
        }

        &.fl {
            float: left;
            width: auto;
            height: 34px;
            line-height: 34px;

            @media screen and (max-width: $media_mobile) {
                width: 100%;
                text-align: left;
            }
        }
    }

    .reg {
        color: #9b9b9b;
    }

    &-control-caret {
        position: absolute;
        right: 7px;
        top: 10px;
    }

    &-group-button {
        @media screen and (min-width: $media_tablet) {
            padding-top: 14px;
        }

        @media screen and (min-width: $media_desktop) {
            padding-top: 0;
        }

        .btn {
            margin-bottom: $marginInner;

            @media screen and (min-width: $media_tablet) {
                margin-bottom: 29px;
            }

            @media screen and (min-width: $media_desktop) {
                margin-bottom: $marginInner;
            }
        }
    }

    .btn {



        &-input-small {
            background-color: #fff;
            border: 1px solid #dadada;
            color: #a0a0a0;
            height: 34px;
            line-height: 34px;
            margin-left: 5px;
            padding: 0;
            text-align: center;
            width: 34px;

            &:hover {
                background-color: #dadada;
            }

            &-absolute {
                bottom: 0;
                position: absolute;
                right: -40px;
            }

            &.fr {
                float: right;
                margin-left: 10px;
            }

            &.fl {
                float: left;
                margin-left: 10px;
            }

            .fa-question {
                font-size: 20px;
                line-height: 34px;
            }
        }

        &--account-name {
            width: 81px;
            margin-left: 10px;
        }
    }

    &-buttons {
        text-align: right;
        width: 100%;
        clear: both;

        .btn {
            width: auto;
        }
    }

    &-group {
        &--account-name {
            background-color: #f0f0f0;
            padding: 10px $paddingInner;
            margin-bottom: 0;
        }

        &--contacts {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 0;
        }
    }

    &-contacts {
        &-count {
            float: right;
            width: 126px;
            height: 40px;
            line-height: 40px;
            text-align: left;
            padding-left: 10px;
        }

        &--history {
            float: right;
            margin-left: 10px;
            margin-top: 3px;
        }

        &--status {
            float: right;
            width: 140px;
            height: 40px;
            line-height: 40px;
            padding-left: 10px;
        }

        &--value {
            float: right;
            width: 140px;
            height: 40px;
            line-height: 40px;
            padding-left: 10px;
            width: 300px;
        }

        &--associate {
            overflow: hidden;
            height: 40px;
            line-height: 40px;
        }
    }

    &-header {
        text-transform: uppercase;
        height: 40px;
        line-height: 40px;

        @media screen and (min-width: $media_tablet) {
            max-width: 560px;
        }

        &--name {
            overflow: hidden;
            float: none;

            @media screen and (min-width: $media_tablet) {
                float: left;
                padding-right: 15px;
            }
        }

        &--count {
            width: 126px;
            float: right;
            padding-left: 10px;
        }

        &--history {
            width: 34px;
            float: right;
            margin-left: 10px;
        }

        &--status {
            width: 140px;
            float: right;
            padding-left: 10px;

            @media screen and (min-width: $media_tablet) {
                padding-left: 0;
            }
        }

        &--associate {
            overflow: hidden;
            float: none;

            @media screen and (min-width: $media_tablet) {
                padding-right: 15px;
            }
        }
    }

    &-block {
        .form-control-out {
            overflow: hidden;
            max-width: none;
            position: relative;

            @media screen and (min-width: $media_tablet) {
                max-width: 370px;
            }

            &--full {
                @media screen and (min-width: $media_tablet) {
                    max-width: none;
                }
            }

            &.fl {
                float: left;
                width: 100%;
                margin-bottom: 5px;

                @media screen and (min-width: $media_tablet) {
                    margin-bottom: 0;
                }
            }

            &--second {
                margin-top: 15px;
            }

            &--center {
                padding-left: 10px;

                @media screen and (min-width: $media_tablet) {
                    padding-left: 0;
                }
            }
        }

        .form-group {
            &--static {
                @media screen and (min-width: $media_tablet) {
                    max-width: 514px;
                }
            }

            &--full {
                position: relative;
            }

            &--trim {
                position: relative;
                margin-right: 40px;
            }
        }

        .control-label {
            color: #000;
            display: block;
            margin-bottom: 0;
            font-size: 10px;

            @media screen and (min-width: $media_tablet) {
                font-size: 12px;
            }

            @media screen and (min-width: $media_desktop) {
                width: 115px;
                float: left;
                height: 34px;
                line-height: 34px;
                font-size: 14px;
                position: relative;
                margin-top: 0;
            }

            &--col {
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 40px;
                line-height: 40px;
                width: auto;
                float: none;

                @media screen and (min-width: $media_tablet) {
                    width: 317px;
                    padding-right: 15px;
                }
            }

            &--visible {
                @media screen and (max-width: $media_mobile) {
                    display: block;
                }
            }

            &--full {
                width: 100%;
            }

            &--account-name {
                @media screen and (min-width: $media_desktop) {
                    width: 118px;
                }
                padding-right: 10px;
                width: 112px;
            }

            &--account-name-large {
                padding-right: 10px;
                width: 170px;
            }

            &--upload {
                width: 90px;
                padding-right: 10px;
                line-height: 34px;
            }

            &--auto {
                width: auto;
                padding-right: 10px;
            }

            &--center {
                padding-left: 10px;
            }
        }

        .form-select-out {
            float: right;
            margin-left: 10px;
            width: 85px;
            position: relative;


            &.overflow {
                overflow: hidden;
            }

            &.fl {
                float: left;
            }

            &.fr {
                float: right;
            }

            &.no-float {
                float: none;
            }

            &--static {
                width: 100%;
                margin-left: 0;

                @media screen and (min-width: $media_tablet) {
                    width: 200px;
                }
            }

            &--second {
                margin-top: 20px;
            }

            .plaseholder {
                @media screen and (min-width: $media_tablet) {
                    display: none;
                }
            }
        }

        .btn-info {
            &--small {
                width: 85px;
                margin-left: 10px;
                padding-left: 0;
                padding-right: 0;
                text-transform: uppercase;
            }
        }

        .form-foreach-fields {
            &--profile {
                max-width: none;
                clear: both;

                @media screen and (min-width: $media_desktop) {
                    padding-left: 100px;
                }

                .modal-body & {
                    @media screen and (min-width: $media_tablet) {
                        padding-left: 100px;
                    }
                }

                .btn-input-small {
                    float: right;
                    margin-left: 10px;
                }

                .form-control-out {
                    @media screen and (min-width: $media_tablet) {
                        max-width: 370px;
                        float: none;
                    }
                }



                .form-group--profile {
                    margin-bottom: 0;
                    margin-top: 15px;
                }
            }
        }

        .intl-tel-input {
            display: block;
            position: static;

            .country-list {
                z-index: 1001;
            }

            .flag-container {
                bottom: auto;
                top: auto;
            }

            .selected-flag {
                height: 34px;
            }
        }
    }

    .label-row {
        margin-bottom: 0;

        &--full {
            width: 100%;
        }
    }

    &-foreach-fields {
        &--profile {
            width: 100%;

            @media screen and (min-width: $media_tablet) {
                max-width: 445px;
            }

            @media screen and (min-width: $media_desktop) {
                padding-left: 90px;
            }
        }
    }

    .select-out {
        position: relative;
    }

    .form-body {
        &--contacts {

            @media screen and (min-width: $media_tablet) {
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }

    .btn-info,
    .btn-primary {
        text-transform: uppercase;
    }

    .btn-default {
        &--auto {
            width: auto;
        }
    }


    .button-table {
        margin-top: 15px;
    }

    .btn-primary {
        background-color: #39afdb;
        border-color: #2d8caf;
        text-transform: uppercase;
    }

    .no-result {
        text-align: center;
        padding-top: 15px;
    }

    &-section {
        padding-bottom: 20px;
    }

    select {
        padding-right: 20px;
    }
}

.field-with-datepicker {
    display: inline-block;
    width: 181px;
    @media screen and (max-width: #{$media_tablet - 1px} ) {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        padding-right: 40px;
    }
    @media screen and (min-width: $media_tablet ) {
        width: 142px;
    }
    @media screen and (min-width: $media_desktop ) {
        width: 181px;
    }
    &--sidebar {
        width: 100px;
    }
}

.collection-datepicker-field {
    @media screen and (max-width: $media_tablet) {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        padding-right: 40px;
    }
}

.spoiler {
    display: none;

    &-link {
        cursor: pointer;
        text-transform: uppercase;
        color: #4a4a4a;

        .fa {
            color: #e6e6e6;
        }
    }
}

.form-group--fastpay {
    @media screen and (min-width: $media_tablet) {
        max-width: 548px !important; ////
    }
}

.form-group--pipeline-report {
    @media screen and (min-width: $media_tablet) {
        min-width: 300px;
        float: left;
        padding: 0 10px;
    }
}

.contacts-table {
    &--pipeline-table {
        td {
            white-space: normal !important;
        }
        th {
            white-space: normal !important;
        }
    }
}

.form-group--history-date {
    @media screen and (min-width: $media_desktop) {
        width: 330px;
        clear: both;
        float: right;
    }
}

.form-group--new-line {
    clear: both;
}
/*******************************
            fieldset
*******************************/
.help-block {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #a94442;


    &.medium {
        @media screen and (min-width: $media_mediummobile) {
            width: 330px;
        }
    }
}

.pipeine-label2 {
    min-width: 120px; //
    float: left;
}

.pipeine-label {
    min-width: 200px; //
    float: left;
}

.pipeine-label-small {
    min-width: 110px; //
    float: left;
}

.help-two-columns {

    @media screen and (min-width: $media_tablet) {
        text-align: center;
    }

    @media screen and (min-width: $media_desktop) {
        padding-left: 125px;
        text-align: center;
    }
}

.fieldset-label {
    &--block-label {
        display: block;
        float: none;
    }
}

.booking-share-table {
    width: 730px;

    tr {
        @media screen and (max-width: 940px) {
            margin-bottom: 15px;
            display: block;
            overflow: hidden;
        }

        td:last-child {
            border: 0;
        }
    }

    @media screen and (max-width: 1280px) {
        width: 675px;
    }
    @media screen and (max-width: 940px) {
        width: 100%;
    }

    .td-1 {
        @media screen and (max-width: 940px) {
            width: 100%;
            float: left;
            padding: 0;
        }
    }

    .td-3 {
        text-align: center;
        @media screen and (max-width: 940px) {
            width: 100px;
            float: left;
            padding: 0;
            text-align: left;
            clear: left;

            label {
                display: inline-block;
                vertical-align: top;
                line-height: 18px;
                width: 80px;
            }
        }
    }

    .td-2 {
        @media screen and (max-width: 940px) {
            float: right;
            padding: 0;
            text-align: right;
            font-size: 0;
            line-height: 0;

            .input-btn {
                width: auto;
                display: inline-block;
            }
        }
    }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default.active[disabled],
.btn-default.focus[disabled],
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover {
    cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
}

.form-control[readonly] {
    &:focus {
        border: 1px solid #ccc;
        @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
    }
}

input[type=text]::-ms-clear,
.form-control::-ms-clear {
    display: none;
}

html.mobile,
html.tablet {
    .select-box {
        //@media screen and (min-width: $media_tablet) {
        //  position: static;
        //}
        select {
            z-index: 200 !important;
            width: 100% !important;
        }
    }
}

.select-box {
    position: relative;

    select {
        width: 100%;
    }

    &--my-contact {
        position: relative;
    }
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999;
}

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor:hover {
  background-color: #fff !important;
}

//.form-group {
.selectboxit-options {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 300px;
    width: 100%;
    z-index: 100000;
    @include border-radius(4px);
    @include box-shadow(1px, 1px, 4px, rgba(0, 0, 0, 0.2));

    @media screen and (min-width: $media_tablet) {
        z-index: 1002;
        //width: 186px;
        //.profile-form-control & {
        //  width: 406px;
        //}
        //.profile-form--my-contacts .profile-form-control & {
        //  width: 188px;
        //}
        //.xxsmall & {
        //  width: 90px;
        //}
        //.xsmall & {
        //  width: 142px;
        //}
        //.xmedium & {
        //  width: 225px;
        //}
        //.medium & {
        //  width: 330px;
        //}
        //.large & {
        //  width: 370px;
        //}
        //.xlarge & {
        //  width: 369px;
        //}
    }

    .profile-form--my-contacts .profile-form-control & {
        @media screen and (min-width: $media_largedesktop) {
            width: 200px;
        }
    }

    .messages-sidebar & {
        margin-top: -15px;
    }

    .select-box--my-contact &,
    .field--profile & {
        width: 100%;
    }
}

.smalldrop {
    .selectboxit-options {
        max-height: 155px!important;
    }

}

.contracts-dropdown {
    .selectboxit-options {
        max-height: 145px;
    }
}


.full {
    width: 100% !important;
}

.selectboxit {
    &-option {
        cursor: pointer;

        &:focus,
        &:active,
        &.selectboxit-focus {
            background-color: #eee;
            text-decoration: none;
        }
    }

    &-option-anchor {
        display: block;
        padding: 4px 10px;
        color: #000;

        &:hover {
            background-color: #eee;
            text-decoration: none;
        }
    }

    &.form-control {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--hotel-contracts {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 15px;
        }
    }

    &-arrow-container {
        position: absolute;
        right: 0;
        width: 30px;
    }

    &-arrow {
        left: 0;
        margin: 8px auto 0;
        position: absolute;
        right: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #000000;
        height: 0;
        width: 0;
    }
}
//}
.table-summary {
    width: 100%;
    color: #4a4a4a;

    td {
        @media screen and (max-width: $media_mobile) {
            display: block;
            width: 50%;
            float: left;
            padding-top: 3px;
            padding-bottom: 3px;
            &:nth-child(2n+1) {
                clear: left;
            }
        }

        @media screen and (min-width: $media_mediummobile) {
            font-size: 14px;
        }

        @media screen and (min-width: $media_tablet) {
            border: 1px solid #f0f0f0;
            padding: 12px;
        }
    }

    &-name {
        width: 35%;
        text-align: right;
        padding-right: 3px;
        text-transform: uppercase;
        font-size: 11px;

        @media screen and (min-width: $media_mediummobile) {
            padding-right: 7px;
        }

        @media screen and (min-width: $media_tablet) {
            text-align: left;
            padding-left: 12px;
            text-transform: none;
        }
        &--mobile {
            @media screen and (max-width: $media_tablet) {
                text-align: left;
            }
        }
    }

    &-data {
        text-align: left;
        width: 15%;
        color: #000;
        padding-left: 3px;
        font-size: 12px;

        @media screen and (min-width: $media_mediummobile) {
            padding-left: 7px;
        }

        @media screen and (min-width: $media_tablet) {
            text-align: right;
            color: #4a4a4a;
            padding-right: 12px;
        }
    }

    &-date {
        min-width: 150px;
    }
}
/*******************************
            checkboxes
*******************************/
.checkbox {
    &--custom {
        input[type=checkbox] {
            display: none;
        }

        .label--custom {
            font: inherit;
            padding-left: 0;

            &.fa:before {
                font-family: FontAwesome;
                display: inline-block;
                font-size: 15px;
                width: 20px;
                vertical-align: middle;
            }

            &.disabled:hover {
                cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
            }

            &.disabled.fa:before {
                opacity: .4;
            }
        }
    }
}
/*******************************
            popup
*******************************/
.popup,
.bootbox.modal .modal-dialog {
    background-color: #fff;
    @include border-radius(4px);
    @include box-shadow(0, 1px, 0, rgba(0, 0, 0, 0.18));

    .modal {
        &-content {
            &--popup {
                background-color: transparent;
                border: 0;
                @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
            }

            &--upload {
                .input-group-btn {
                    width: 110px;
                    height: 32px;
                    padding-left: 10px;
                }

                .help-block {
                    font-size: 12px;
                    color: #5b5b5b;
                    margin-top: 0;
                    padding-top: 10px;
                }

                .btn-default--upload {
                    border: 0;
                    @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));

                    input {
                        width: 110px;
                    }
                }

                .form-group {
                    margin-bottom: 0;
                }

                .modal-footer {
                    padding-top: 0;
                }
            }
        }

        &-body {
            padding: $paddingInner $paddingInner 0 $paddingInner;
            position: static;

            &--account-name {
                padding-top: 0;
            }

            .fileinput-button {
                padding: 0;
                position: relative;
                cursor: pointer;
                border: 1px solid #ccc;
                @include box-shadow(0, 1px, 1px, rgba(0, 0, 0, 0.075));
                @include border-radius(4px);
                float: right;
                margin-left: 10px;
                overflow: hidden;

                &:hover,
                &:focus,
                &:active {
                    color: #333;
                    background-color: #e6e6e6;
                    border-color: #adadad;
                }

                &-text {
                    display: block;
                    height: 32px;
                    font-size: 14px;
                    line-height: 32px;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    top: 0;
                    bottom: 0;

                    &:focus,
                    &:active {
                        color: #333;
                        background-color: #e6e6e6;
                        border-color: #adadad;
                    }
                }
            }

            .form-control--upload {
                width: 100%;
                margin-right: 10px;
                @include border-radius(4px);

                @media screen and (min-width: $media_tablet) {
                    margin-right: 0;
                }
            }

            .input-group--upload {
                width: 100%;
            }

            input[type='file'] {
                color: transparent;
                opacity: 0;
                position: absolute;
                left: 0;
                width: 110px;
                height: 34px;
                cursor: pointer;
                bottom: 0;
                //&:focus,
                //&:active {
                //  position: absolute;
                //  left: 0;
                //  z-index: -1;
                //}
            }

            &--congratulations {
                padding: $paddingInner;
                text-align: center;
            }

            &--scroll {
                @media screen and (min-width: $media_tablet) {
                    height: 458px;
                    max-height: 530px;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }

            &--edit-history-rate {
                padding-bottom: 52px;
            }
        }

        &-footer {
            border: 0;
            padding: $paddingInner;

            .btn {
                width: auto;
                height: 34px;
                margin-left: 10px;
            }

            .btn-info {
                text-transform: uppercase;
            }

            &--no-padding {
                padding-top: 0;
            }
        }

        &-header {
            background-color: #39afdb;
            color: #fff;
            text-align: center;
            line-height: 34px;
            text-transform: uppercase;
            padding: 0;
            @include border-radius(4px 4px 0 0);

            .modal-title {
                line-height: 34px;
                font-size: 14px;

                .fa-file-o {
                    //display: none;
                }
            }

            .close {
                color: #fff;
                cursor: pointer;
                font-size: 22px;
                position: absolute;
                right: 20px;
                top: 5px;
                margin-top: 0;

                @media screen and (min-width: $media_tablet) {
                    display: none;
                }
            }
        }

        &-message-header {
            background-color: #f2f2f2;
            padding: $paddingInner;

            .label {
                color: #4a4a4a;
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                text-align: right;
                width: 70px;
                padding-left: 0;
            }
        }

        &-message {
            color: #000;
        }
    }

    &-title {
        background-color: #39afdb;
        color: #fff;
        text-align: center;
        line-height: 34px;
        text-transform: uppercase;
        @include border-radius(4px 4px 0 0);
        overflow: hidden;
        padding: 0 30px 0 10px;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (min-width: $media_tablet) {
            padding: 0;
        }
    }

    &-confirm {
        width: 288px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90px;

        &--contacts {
            @media screen and (min-width: $media_tablet) {
                width: 425px;
            }
        }
    }

    &-upload {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        @media screen and (min-width: $media_tablet) {
            width: 424px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &--history {
        width: 900px;
    }

    &--help-form {
        z-index: 1000000;

        @media screen and (min-width: $media_tablet) {
            width: 372px;
        }
    }

    .modal-content-help-form {
        @media screen and (min-width: $media_tablet) {
            height: 495px;
            position: relative;
        }

        .success {
            @media screen and (min-width: $media_tablet) {
                padding-top: 80px;
            }
        }

        .popup-buttons {
            @media screen and (min-width: $media_tablet) {
                bottom: 0;
                position: absolute;
                width: 100%;
            }
        }
    }

    &--full {
        @media screen and (max-width: $media_mobile) {
            width: 100%;
            min-height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin-top: 0;
            margin-left: 0;
            padding-left: 0;
        }
    }

    &-form {
        &--new-contact {
            @media screen and (min-width: $media_tablet) {
                width: 510px;
            }
        }

        &--collection-edit {
            @media screen and (min-width: $media_tablet) {
                width: 375px;
            }

            @media screen and (min-width: $media_desktop) {
                width: 540px;
            }
        }

        &--new-account {
            @media screen and (min-width: $media_tablet) {
                width: 650px;
            }
        }

        &--edit-rate {
            @media screen and (min-width: $media_tablet) {
                width: 735px;
            }
        }

        &--edit-addendum {
            @media screen and (min-width: $media_tablet) {
                width: 630px;
            }
        }

        .form {
            @media screen and (min-width: $media_tablet) {
                max-height: 481px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }

    &-close {
        color: #fff;
        cursor: pointer;
        font-size: 22px;
        position: absolute;
        right: 20px;
        top: -1px;

        @media screen and (min-width: $media_tablet) {
            display: none;
        }
    }

    .list-attach {
        margin-top: 20px;
        list-style: none;
        border-top: 1px dashed #39afdb;
        padding: 10px 10px 10px 0;

        &-link {
            text-decoration: none;

            &:hover {
                color: #1d344f;
            }
        }
    }
}

.bootbox-confirm .modal-dialog {
    width: 288px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.345) !important;
}

#modalAddOrganization.fade,
.modal-video.fade {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    z-index: 1070;
}

#modalNewAccount.fade {
    z-index: 1070;
}

.modal-backdrop + .modal-backdrop {
    z-index: 1060;
}

.bootbox.modal .modal-dialog {
    .btn-primary {
        background-color: #5bc0de;
        border-color: #46b8da;
        color: #fff;
        text-transform: uppercase;
    }
}

.bootbox-alert {
    display: block;
    z-index: 1090;
}

.bootbox-alert + .modal-backdrop {
    z-index: 1080;
}

.modal-video {
    background: transparent;
    padding: 0 !important;
    width: 100%;

    .popup {
        border: 0;
        @include border-radius(6px);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        background: #fff;
    }

    .modal-dialog {
        width: 100%;
        margin: 0;
        //background-color: #fff;
        @media screen and (min-width: $media_tablet) {
            width: 680px;
            margin: 30px auto 0;
        }
    }

    .modal-body {
        padding: 0;
        text-align: center;
        //background-color: #fff;
    }
    //.popup-close {
    //  z-index: 200;
    //  font-size: 20px;
    //  @media screen and (min-width: $media_tablet) {
    //    display: block;
    //    font-size: 34px;
    //  }
    //}
    .popup-content {
        padding: 20px;
        text-align: left;

        @media screen and (min-width: $media_tablet) {
            overflow-y: auto;
            max-height: 600px;
        }

        .popup-video-content {
            //position: relative;
            //padding-bottom: 56.25%;
            //padding-top: 30px;
            //height: 0;
            //overflow: hidden;
        }

        iframe, object, embed {
            //position: absolute;
            //top: 0;
            //left: 0;
            //width: 100%!important;
            //height: 100%!important;
            //max-width: 640px;
            width: 320px;
            height: 180px;

            @media screen and (min-width: $media_mediummobile) {
                width: 480px;
                height: 270px;
            }

            @media screen and (min-width: $media_tablet) {
                width: 640px;
                height: 360px;
            }
        }
    }
}


.modal-widget-message {
    z-index: 1080;
}
/*******************************
            reserves count
*******************************/
.reserves-count {
    &-col {
        margin-bottom: $marginInner;
        text-align: center;

        @media screen and (min-width: $media_tablet) {
            float: left;
            width: 33.33%;
            text-align: left;
            margin-bottom: 0;
        }

        &--center {
            @media screen and (min-width: $media_tablet) {
                text-align: center;
            }
        }

        &--right {
            @media screen and (min-width: $media_tablet) {
                text-align: right;
            }
        }
    }

    &-col:last-child {
        margin-bottom: 0;
    }

    &-data,
    &-label {
        width: 100%;
        display: inline-block;
        vertical-align: middle;

        @media screen and (min-width: $media_tablet) {
            width: auto;
        }
    }

    &-data {
        font-size: 24px;
        font-weight: 300;
        color: #3aafda;

        @media screen and (min-width: $media_tablet) {
            font-size: 36px;
        }
    }

    &-label {
        font-size: 12px;

        @media screen and (min-width: $media_tablet) {
            max-width: 85px;
            text-align: left;
        }
    }
}
/*******************************
            contacts
*******************************/
#prefrules-contracts,
#associatesHierarchy {

    .btn-input-tabs:last-child {
        @media screen and (min-width: $media_tablet) and (max-width: $media_largedesktop) {
            margin-left: 0;
        }
    }

    .td-3-button-utils, .td-action--triple {
        @media screen and (min-width: $media_tablet) and (max-width: $media_largedesktop) {
            width: 120px !important;
            min-width: 120px !important;
        }
    }

    .td-create {
        @media screen and (min-width: $media_tablet) and (max-width: $media_largedesktop) {
            width: 90px !important;
            min-width: 90px !important;
        }
    }

    td, th {
        @media screen and (min-width: $media_tablet) and (max-width: $media_largedesktop) {
            font-size: 11px;
            padding: 4px;
        }
    }
}

body .contacts .contacts-table td.contacts-auto-width {
    width: 210px !important;
    @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 280px !important;
    }
}

.contact-share-limit {
    width: 100%;
    orcder:-1;
}

.contacts {


    @media screen and (max-width: #{$media_tablet - 1px}) {
        .my-contacts-btn {
            display: block;
            margin: 0 auto;
            position: relative;
            bottom: 8px;
        }
    }

    .field-with-button-cont {
        @media screen and (min-width: #{$media_tablet - 1px}) {

        }
        @media screen and (max-width: #{$media_tablet - 1px}) {

        }
    }

    .qwe {
            display: flex !important;
            flex-flow: row nowrap;
    }

    .col-contacts-xs {
        @media screen and (max-width: #{$media_tablet - 1px}) {
            display: flex !important;
            flex-flow: row nowrap;

        }
    }

    .contacts-hidden {
        @media screen and (min-width: #{$media_tablet - 1px}) {
            display: none;
        }
    }




    &-table {
        width: 100%;

        @media screen and (max-width: $media_mobile) {
            table-layout: fixed;
        }

        &--border {
            @media screen and (min-width: $media_tablet) {
                border: 1px solid #f0f0f0;
            }
        }

        th,
        td {
            padding: 0 $paddingInner;

            @media screen and (min-width: $media_tablet) {
                padding: 0 10px;
            }

            @media screen and (min-width: $media_desktop) {
                padding: 0 $paddingInner;
            }
        }

        th {
            font-weight: 400;
            text-align: left;
            text-transform: uppercase;
            border-bottom: 2px solid #ddd;
            height: 28px;
            font-size: 12px;
            @media screen and (min-width: $media_tablet) {
                font-size: 14px;
            }
        }


        td {
            color: #000;
            font-size: 12px;
            vertical-align: middle;

            &.trim {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;


                span {
                    @media screen and (max-width: $media_mobile) {
                        //      display: inline-block; ////
                    }

                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    white-space: nowrap;
                }

                &-link {
                    color: #4a90e3;

                    .file-attributes {
                        display: none;
                        color: #000;

                        @media screen and (max-width: $media_mobile) {
                            display: block;
                        }
                    }
                }

                @media screen and (min-width: $media_tablet) {
                    max-width: 0;
                }
            }

            @media screen and (min-width: $media_tablet) {
                height: 42px;
            }

            &.td-button {
                padding-top: 3px;
                padding-bottom: 10px;
                height: auto;
                text-align: right;

                @media screen and (min-width: $media_tablet) {
                    padding-bottom: 0;
                    padding-top: 0;
                    width: 106px;
                    max-width: 140px;
                }

                .btn {
                    float: none;
                    border: 1px solid #dadada;
                }

                .apply-button-label {
                    @media screen and (min-width: $media_tablet) {
                        display: inline-block;
                        margin-left: 5px;
                        font-size: 12px;
                    }
                }

                .apply-button-icon {
                    color: #a0a0a0;

                    @media screen and (min-width: $media_desktop) {
                        color: #333;
                    }
                }
            }

            &:last-child {
                @media screen and (max-width: $media_mobile) {
                    border-bottom: 1px solid #f0f0f0;
                    padding-bottom: 8px;
                }
            }

            &:first-child {
                @media screen and (max-width: $media_mobile) {
                    padding-top: 8px;
                }
            }
        }

        &--responsive {
            @media screen and (max-width: $media_mobile) {
                display: block;
                width: 100%;
            }

            tbody, tr {
                @media screen and (max-width: $media_mobile) {
                    display: block;
                    width: 100%;
                }
            }

            td {


                @media screen and (max-width: $media_mobile) {
                    display: block;
                    padding: 2px 0 0;
                    padding-bottom: 2px;
                    font-size: 14px;
                }

                &.trim {
                    @media screen and (max-width: $media_mobile) {
                        width: 98%;
                    }
                }
            }
        }

        .no-records-found td {
            text-align: center;
            height: auto;
        }

        &--admin {
            border: 1px solid #f0f0f0;
        }

        .td-action {
            line-height: 36px;
            height: auto;

            @media screen and (min-width: $media_tablet) {
                width: 60px;
                max-width: 60px;
                text-align: center;
                line-height: 26px;
            }

            &--multiple {
                @media screen and (min-width: $media_tablet) {
                    min-width: 100px;
                    max-width: 100px;
                }
            }

            &--single {
                @media screen and (min-width: $media_tablet) {
                    min-width: 50px;
                    max-width: 50px;
                }
            }

            &--triple {
                width: 135px;
                min-width: 135px !important;

                @media screen and (min-width: 0) and (max-width: $media_mobile) {
                    width: 100%;
                    height: 55px;
                }
            }
        }

        .td-id {
            @media screen and (min-width: $media_tablet) {
                width: 70px;
                max-width: 80px;
            }
        }

        .td-name {
            @media screen and (min-width: $media_tablet) {
                width: auto;
            }
        }

        .td-date {
            @media screen and (min-width: $media_tablet) {
                max-width: 80px;
                width: 80px;
            }

            @media screen and (min-width: $media_desktop) {
                max-width: 150px;
                width: 150px;
            }
        }

        .td-date-small {
            @media screen and (min-width: $media_tablet) {
                max-width: 90px;
                width: 90px;
            }
        }

        .td-3-button-utils {

            @media screen and (max-width: $media_mobile) {
                height: 50px;
            }

            @media screen and (min-width: $media_tablet) {
                width: 145px !important;
                min-width: 145px !important;
            }
        }




        .td-date-min {
            @media screen and (min-width: $media_tablet) {
                max-width: 80px;
                width: 80px;
            }
        }

        .td-booking-type {
            @media screen and (min-width: $media_tablet) {
                max-width: 160px;
                width: 160px;
            }
        }

        .td-date-brands {
            @media screen and (min-width: $media_tablet) {
                max-width: 112px;
                width: 112px;

                &.t-right {
                    text-align: right;
                }

                &.t-left {
                    text-align: left;
                }
            }
        }

        .td-date-brands-middle {
            @media screen and (min-width: $media_tablet) {
                max-width: 112px;
                width: 120px;

                &.t-right {
                    text-align: right;
                }

                &.t-left {
                    text-align: left;
                }
            }
        }

        .td-preferred-window {
            @media screen and (min-width: $media_tablet) {
                max-width: 140px;
                width: 140px;
            }
        }

        .td-created-by {
            @media screen and (min-width: $media_tablet) {
                max-width: 300px;
            }

            @media screen and (min-width: $media_desktop) {
                max-width: 400px;
            }
        }


        .td-extra-small {
            @media screen and (min-width: $media_tablet) {
                max-width: 50px;
                width: 50px;

                &.t-right {
                    text-align: right;
                }

                &.t-left {
                    text-align: left;
                }
            }
        }

        .td-one-control {
            @media screen and (min-width: $media_tablet) {
                max-width: 50px;
                width: 50px;
            }

            @include clearfix;
        }

        .td-create {
            @media screen and (min-width: $media_tablet) {
                max-width: 110px;
                width: 110px;
            }

            @media screen and (min-width: $media_desktop) {
                max-width: 125px;
                width: 125px;
            }
        }

        .td-payments-summary {
            //@media screen and (min-width: $media_tablet) {
            //    max-width: 110px;
            //    width: 110px;
            //}

            @media screen and (min-width: $media_desktop) {
                max-width: 155px;
                width: 155px;
            }
        }

        .td-small-column {
            @media screen and (min-width: $media_tablet) {
                max-width: 80px;
                width: 80px;
            }

            @media screen and (min-width: $media_desktop) {
                max-width: 100px;
                width: 100px;
            }
        }


        .td-added {

            @media screen and (min-width: $media_tablet) {
                max-width: 110px;
                width: 110px;
            }
        }


        .td-size {


            @media screen and (min-width: $media_tablet) {
                max-width: 70px;
                width: 70px;
            }
        }

        .td-history-name {
            @media screen and (min-width: $media_tablet) {
                max-width: 170px;
                width: 170px;
            }
        }

        .td-small {
            @media screen and (min-width: $media_tablet) {
                max-width: 80px;
                width: 80px;
            }
        }

        .td-xsmall {
            @media screen and (min-width: $media_tablet) {
                max-width: 65px;
                width: 65px;
            }
        }

        .td-msmall {
            @media screen and (min-width: $media_tablet) {
                max-width: 75px;
                width: 75px;
            }
        }

        .td-contact-name {
            @media screen and (min-width: $media_tablet) {
                max-width: 200px;
                width: 200px;
            }

            @media screen and (min-width: $media_largedesktop) {
                max-width: 300px;
                width: 300px;
            }
        }

        .td-contact {
            word-wrap: break-word;

            @media screen and (min-width: $media_tablet) {
                max-width: 100px;
                width: 100px;
            }
        }

        .td-count {
            word-wrap: break-word;
            max-width: 100px;
            width: 100px;
            text-align: right;

            .btn--small {
                margin-left: 10px;
                float: none;
            }
        }

        .td-remaining {
            @media screen and (min-width: $media_tablet) {
                width: 100px;
            }
        }

        td.t-right {
            @media screen and (min-width: $media_tablet) {
                text-align: right;
            }
        }

        td.t-left{
            @media screen and (min-width: $media_tablet) {
                text-align: left;
            }
        }

        th.t-right {
            @media screen and (min-width: $media_tablet) {
                text-align: right;
            }
        }

        th.t-left {
            @media screen and (min-width: $media_tablet) {
                text-align: left;
            }
        }

        .td-description {
            @media screen and (min-width: $media_tablet) {
                width: 200px;
            }

            @media screen and (min-width: $media_mediumdesktop) {
                width: 100px;
            }

            @media screen and (min-width: $media_desktop) {
                width: 150px;
            }

            @media screen and (min-width: $media_standartdesktop) {
                width: 200px;
            }

            @media screen and (min-width: $media_largedesktop) {
                width: 350px;
            }
        }

        &-label {
            color: #4a4a4a;
            text-transform: uppercase;
            display: inline-block;
            min-width: 100px;
            padding-right: 10px;
            text-align: right;

            @media screen and (min-width: $media_tablet) {
                display: none;
            }
        }

        &-avatar {
            @include border-radius(50%);
            overflow: hidden;
            width: 23px;
            height: 23px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            border: 1px solid #d8d8d8;

            @media screen and (min-width: $media_tablet) {
                margin-right: 5px;
            }

            @media screen and (min-width: $media_desktop) {
                margin-right: 10px;
            }

            img {
                //height: auto;
                //max-width: 100%;
                //min-height: 100%;
                //min-width: 100%;
                //left: 50%;
                //position: relative;
                //-ms-transform: translate(-50%, 0);
                //-webkit-transform: translate(-50%, 0);
                //transform: translate(-50%, 0);
                height: 100%;
                left: 50%;
                max-width: none;
                min-height: 100%;
                min-width: 100%;
                position: relative;
                -ms-transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
            }
        }

        th.th--nowrap {
            white-space: nowrap;
        }

        .icon-status {
            display: inline-block;
            font-size: 16px;
            vertical-align: middle;
        }

        .btn {
            width: auto;
            height: 34px;

            &--big {
                width: 90px;
                text-align: left;

                @media screen and (min-width: $media_tablet) {
                    width: auto;
                    text-align: center;
                }

                @media screen and (min-width: $media_desktop) {
                    width: 90px;
                    text-align: left;
                }
            }

            .fa {
                color: #a0a0a0;
            }

            @media screen and (max-width: $media_mobile) {
                float: right;
            }

            &-input-tabs {
                width: 34px;
                height: 34px;
                padding: 0;
                line-height: 34px;
                text-align: center;
                color: #a0a0a0;
                border: 1px solid #dadada;
                background-color: #fff;
                margin-left: 5px;
                float: right;
                position: relative;
                z-index: 100;

                &:hover {
                    background-color: #dadada;
                }

                &:focus,
                &:active {
                    outline: none;
                }

                &.disabled:hover,
                &.disabled:focus {
                    background-color: transparent;
                }
            }

            &--small {
                background-color: #fff;
                border: 1px solid #dadada;
                color: #a0a0a0;
                height: 34px;
                line-height: 34px;
                margin-left: 5px;
                padding: 0;
                text-align: center;
                width: 34px;

                &:hover {
                    background-color: #dadada;
                }

                &.disabled:hover,
                &.disabled:focus {
                    background-color: transparent;
                }
            }
        }

        th.th-small-font {
            @media screen and (min-width: 941px) and (max-width: 1259px) {
                font-size: 10px; //////
            }
        }

        th.th-notvisible {
            @media screen and (max-width: $media_mobile) {
                display: none;
            }
        }

        td.th-notvisible.trim {
            &.td-create {
                color: #000 !important;
            }
        }

        th.th--indent {
            padding-left: 70px;
        }

        .icon-status {
            color: #39afdb;
            margin-right: 10px;

            @media screen and (min-width: $media_tablet) {
                margin-right: 5px;
            }

            @media screen and (min-width: $media_desktop) {
                margin-right: 10px;
            }

            &.green {
                color: #4cae4c;
            }

            &.red {
                color: #ffa3a3;
            }
        }

        .reservationHistoryBtn {
            color: #4a90e3;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        .contacts-table-cell {
            vertical-align: middle;
        }

        .contacts-table-link {
            color: #4a90e3;
            cursor: pointer;
        }

        .account-table-data {
            display: inline-block;
            vertical-align: middle;
        }

        tr.ui-sortable-helper {
            display: table;
        }
    }

    .th-notvisible {
        div {
            display: inline;
        }
    }
}


.prefrules-contracts {
    th {
        .td-small {
            width: 50px !important;
        }
    }
}

.pagination-nav,
.dataTables_paginate {
    text-align: center;

    .active {
        a,
        a:focus,
        a:hover {
            background-color: #39afdb;
            border-color: #39afdb;
        }
    }
}
/*******************************
            admin
*******************************/
.admin {
    &-content {
        margin: -10px auto 0;
        width: 240px;

        @media screen and (min-width: $media_smallmobile) {
            width: 360px;
        }

        @media screen and (min-width: $media_tablet) {
            margin: -10px -10px 0;
            width: auto;
        }

        .dashboard {
            &-item {
                display: block;
                float: left;
                margin: 10px;
                background-color: #f3f3f3;
                text-align: center;
                color: #051d3a;
                width: 100px;
                height: 100px;
                padding: 15px 15px 10px;
                text-decoration: none;
                position: relative;
                @include border-radius(4px);

                &:hover {
                    opacity: .8;
                }

                &-icon {
                    font-size: 40px;
                }

                &-text {
                    display: block;
                    min-height: 40px;
                    font-size: 12px;
                    line-height: 16px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }
    }
}
/******************************/
.vakata-context {
    display: none;
    height: 0;
}


.modalWithInfiniteTable {
    .popup-form .form {
        max-height: none;

        .modal-body {
            max-height: 350px;

            @media screen and (min-width: $media_tablet) {
                max-height: 480px;
            }

            overflow-y: auto;
        }
    }
}

.searchResultsTable {
    th {
    }

    .td-contact {
        text-align: right;
        width: 100px;
    }

    .btn--small {
        float: none;
        margin-left: 10px;
    }

    &.contacts-table td {
        display: table-cell;
    }
}


.contacts-table-label {
    vertical-align: middle;
    min-width: 120px !important;
    border-left: 10px;

    &--preferred {
        min-width: 135px !important;
    }
}

.contacts-table-data {
    vertical-align: middle;
}
/*******************************
            cd resources
*******************************/
.folder-list {
    //overflow-x: auto;
    font-size: 12px;
    min-height: 70px;
    max-height: none;
    padding-top: 5px;
}

.cd-resources {
    &__aside {
        display: none;

        @media screen and (min-width: $media_tablet) {
            display: block;
            width: 25%;
            float: left;
        }
    }

    &__table-out {
        width: 100%;
        //max-height: 500px;
        @media screen and (min-width: $media_tablet) {
            width: 75%;
            float: left;
            padding-left: 20px;
        }
    }

    &__icon {
        font-size: 40px;
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        color: #000;

        @media screen and (min-width: $media_tablet) {
            font-size: 14px;
        }

        &--move {
            @media screen and (max-width: $media_tablet) {
                display: none;
            }
        }

        &--report-file {
            font-size: 17px;
            float: left;
        }
    }

    &__icon-block {
        float: left;
        vertical-align: bottom;

        @media screen and (min-width: $media_tablet) {
            float: none;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__icon--move {
        color: #B2B2B2;
    }

    .jstree {
        &-icon {
            font-size: 14px;

            &.jstree-ocl {
                width: 15px !important;
            }
        }
    }

    &__attributes-block {
        //display: inline-block;
        @media screen and (min-width: $media_tablet) {
            display: inline;
            vertical-align: middle;
        }
    }

    &__file-name {
        font-weight: bold;

        @media screen and (min-width: $media_tablet) {
            font-weight: normal;
        }
    }

    td {
        &.td-notvisible {
            @media screen and (max-width: $media_mobile) {
                display: none;
            }
        }
    }

    .jstree-wholerow-ul {
        display: block;
        min-width: auto;
    }

    ul.jstree-children {
        padding-left: 24px;

        &.jstree-container-ul {
            padding-left: 0;
        }
    }

    .jstree-container-ul.jstree-children > li > .jstree-children {
        padding-left: 0;
    }

    li.jstree-node {
        margin-left: 0;
        display: block;
        width: 100%;
        @include text-overflow;
        position: relative;
        padding-right: 5px;

        &:hover > .jstree-anchor {
            color: #4a90e3;
        }
    }

    .jstree-anchor {
        display: inline;
        padding: 0;
    }
    //header files list
    .jstree-container-ul {
        & > li > .jstree-icon {
            display: none;
        }

        & > li > a.jstree-anchor {
            display: block;
            padding: 4px;
            font-size: 14px;
            border-bottom: 2px solid #ddd;
            font-weight: 400;
            text-transform: uppercase;
            height: 37px;
            margin-bottom: 14px;

            &:hover {
                color: #333;
            }
        }

        & > li:hover > a.jstree-anchor {
            color: #333;
        }

        & > li > a > .jstree-icon {
            display: none;
        }

        & > li > .jstree-wholerow-clicked {
            background: transparent;
        }
    }
}

.fastpay-modal-field {
    @media screen and (min-width: $media_tablet) {
        width: 469px !important;
    }

    @media screen and (min-width: $media_desktop) {
        width: 369px !important;
    }
}

.file-attributes {
    font-size: 12px;

    @media screen and (min-width: $media_tablet) {
        display: none;
    }
}

.loader-files {
    text-align: center;
    padding-top: 30px;
}

#custom-popover {
    position: fixed;
    margin: 0;

    &.js-active {
        display: block;
    }
}
/*******************************
            dashboard
*******************************/
.dashboard-grid {
    &s {
        margin: 20px -10px 0;
    }

    @media screen and (min-width: $media_tablet) {
        width: 50%;
        float: left;
        //padding: 20px 10px 0;
    }

    @media screen and (min-width: $media_desktop) {
        width: 33.33%;
        float: left;
        max-width: 380px;
        //padding: 20px 10px 0;
    }

    @media screen and (min-width : $media_tablet) and (max-width: $media_desktop) {
        &--float {
            float: right; // for the desired float Event Calendar
        }
    }
    //@media screen and (min-width: $media_tablet) {
    //    margin: 20px -10px 0;
    //}
    //-moz-column-width: 26em;
    //-webkit-column-width: 26em;
    //column-width: 26em;
}

.dashboard-grid {
    //@media screen and (min-width: $media_xlargedesktop) {
    //    width: 40%;
    //    &--one-block {
    //        width: 20%;
    //    }
    //}
    //&:first-child {
    //    padding-left: 0;
    //}
    //&:last-child {
    //    padding-right: 0;
    //}
}

.dashboard-block {
    @media screen and (min-width: $media_tablet) {
        //width: 33.33%;
        //float: left;
        //margin-left: 10px;
        //margin-right: 10px;
        //display: inline-block;
        //width: 33.33%;
        //vertical-align: top;
    }

    padding: 0 10px;
    margin-bottom: 20px;
    //@media screen and (min-width: $media_xlargedesktop) {
    //    width: 50%;
    //    float: left;
    //    //padding: 20px 10px 0;
    //    .dashboard-grid--one-block & {
    //        width: 100%;
    //    }
    //}
    &__header {
        background-color: #fff;
        border-radius: 5px 5px 0 0;

        .title {
            color: #000;
            text-transform: uppercase;
            border-bottom: 1px solid #dedede;
            padding: 0 15px;
            line-height: 35px;
        }
    }

    &__container {
        padding: 15px;
        background-color: #fff;
        @include border-radius(4px);
        @include box-shadow(0, 1px, 1px, 0, rgba(0, 0, 0, 0.18));
    }

    &__subtitle {
        font-weight: bold;
        color: #000;
        padding-bottom: 7px;
        padding-top: 5px;

        &:first-child {
            padding-top: 0;
        }
    }

    &__body {
        #dashboardNewsList {
            .message {
                font-size: 12px;
                text-align: center;
                color: #979797;
            }

            h2 {
                font-size: 14px;
                color: #000;
                padding-bottom: 7px;
                margin: 20px 0 5px 0;
                font-weight: bold;

                &:first-child {
                    padding-top: 0;
                    margin-top: 0;
                }
            }

            h3 {
                margin: 0;
                padding: 0 3px 8px 0;
                color: #3aafda;
                font-size: 14px;
                @include text-overflow;
                line-height: 16px;

                span {
                    cursor: pointer;

                    &:hover {
                        color: #23527c;
                        text-decoration: underline;
                    }
                }
            }

            p {
                display: none;
            }
        }
    }
}

.dashboard-list {
    padding-left: 0;
    margin-bottom: 0;

    &__item {
        list-style: none;
        padding-bottom: 8px;

        &--trim {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &--menu {
            padding-left: 24px;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__link {
        color: #3aafda;
    }

    &__icon {
        width: 17px;
        text-align: center;
        margin-right: 7px;
        margin-left: -24px;
    }
}

.action-list {
    padding: 0 12px;
    color: #555;
    margin-bottom: 0;

    &--clear {
        padding: 0;
    }

    &__item {
        list-style: none;
        padding-bottom: 8px;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__item-left {
    }

    &__item-right {
        float: right;

        &.blue {
            color: #3aafda;
            font-weight: bold;
        }
    }

    &__link {
        color: #3aafda;
    }
}

.events-calendar {
    &-container {
        margin-bottom: 15px;
    }

    &-list {
        &__item {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .events-list {
                list-style: none;
                margin: 0 0 10px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &__item {
                    .events-id {
                        color: #3aafda;
                    }

                    .events-link {
                    }

                    .events-end {
                        color: #979797;
                    }

                    &--trim {
                        @include text-overflow;
                    }
                }
            }
        }
        /*&__item {
            border-left: 1px solid #e0e0e0;
            padding-left: 5px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__title {
            color: #3aafda;
            font-weight: bold;
            &--trim{
                @include text-overflow;
            }
        }
        &__link {
            color: #3aafda;
        }
        &__body {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        &__date {
            color: #979797;
        }*/
    }

    &-no-events {
        padding: 0 15px;

        .message {
            font-size: 12px;
            text-align: center;
            color: #979797;
        }
    }
}

.markItUpHeader {
    padding: 20px 20px 0 20px;
    background: #fff;

    ul {
        margin: 0;
        padding: 0;
        @include clearfix;

        li {
            width: auto;
            list-style: none;
            float: left;
            position: relative;
            margin: 5px;

            &:first-child {
                margin-left: 0;
            }
        }

        a {
            text-decoration: none;
            color: #000;
            display: block;
        }
    }
}

.markItUp {
    width: 100%;

    .markItUpHeader {
        display: none;
    }

    .markItUpEditor {
        width: 100%;
        border: 1px dotted #ccc;
        clear: both;
        resize: none;
        padding: 5px;
        overflow: hidden !important;
        word-wrap: break-word;
    }
}
/*******************************
            dropzone
*******************************/
.dropzone {
    width: 100%;
    height: 240px;
    border: 1px dashed #39afdb;
    text-align: center;
    position: relative;

    @media screen and (min-width: $media_mediummobile) {
        width: 240px;
        margin: 15px auto 0;
    }

    @media screen and (min-width: $media_tablet) {
        width: 310px;
        height: 320px;
    }

    @media screen and (min-width: $media_desktop) {
        margin: 0 auto;
    }

    &.dz-clickable {
        cursor: pointer;
    }

    .dz-text {
        color: #39afdb;
        font-size: 20px;
        line-height: 28px;
        padding-top: 50px;
        margin: 0 auto;
        max-width: 160px;
    }

    .dz-button {
        width: auto;
        position: absolute;
        bottom: 22px;
        @include centered('x');
    }
}
/*******************************
            for iphone
*******************************/
.iphone.safari {
    /* disable auto-zoom on iphone input field focus */
    select:focus,
    textarea:focus,
    input:focus,
    input[type='text']:focus,
    input[type='number']:focus,
    input[type='email']:focus {
        font-size: 14px;
    }
}


.note-toolbar {
    text-align: left;
}

.note-btn-group {
    .btn {
        width: auto;
    }
}
/*******************************
            for safari
*******************************/
.safari {
    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
        -webkit-text-fill-color: rgba(85, 85, 85, 1);
        -webkit-opacity: 1;
    }

    .form-control[disabled]::-webkit-input-placeholder,
    .form-control[readonly]::-webkit-input-placeholder,
    fieldset[disabled] .form-control::-webkit-input-placeholder {
        -webkit-text-fill-color: rgba(153, 153, 153, 1);
        -webkit-opacity: 1;
    }
}
/*******************************
            dashboard
*******************************/
.dashboard {
    .datepicker {
        width: auto;
        max-width: 240px;
        padding: 0;
        margin: 0 auto;

        table {
            min-width: 220px;
            width: 100%;

            td {
                border-radius: 10px;

                &.highlight {
                    border-radius: 10px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        border: 2px solid #39afdb !important;
                        border-radius: 10px;
                    }
                }

                &.active {
                    background: none !important;
                    text-shadow: none !important;
                    color: #999 !important;
                }

                &.today {
                    background-image: none !important;
                    background: #39afdb !important;
                    color: #ffffff !important;
                }
            }
        }
    }

    .highlight {
        //border: 2px solid #39afdb;
        &-range {
            /*background: #39afdb;
            opacity: 0.7; */
        }
    }
}
/*******************************
            error page
*******************************/

.error-wrap {
    overflow: auto;
    text-align: center;
    white-space: nowrap;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    box-sizing: border-box;

    .container {
        padding: 30px 24px;
        text-align: center;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        white-space: normal;
        background: #fff;
        border-radius: 5px;
        width: auto;
        max-width: 288px;

        header {
            margin-bottom: 20px;

            h2 {
                font-size: 20px;
                font-weight: 600;
                margin-top: 0;
            }
        }

        footer {
            text-align: left;
            font-size: 16px;
        }
    }

    .valign-helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0;
    }
}

#ContentEdit_Description {
    width: 330px;

    @media screen and (max-width: $media_mobile) {
        width: 100%;
    }
}

#editor-panel {
    .panel-body {
        min-height: 243px;
        overflow: visible;
    }

    .form-group {
        .field.medium {
            position: relative;
        }
    }
}

#btnUpInTree {
    width: 100%;

    @media screen and (min-width: $media_mobile) {
        display: none;
    }
}
// popup
//.modalWithInfiniteTable {
//    #a_jsAccountsTable {
//        th,
//        td {
//            width: 100%;
//        }
//    }
//}

.dropdown-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        padding-right: 20px;
}

#contentEditForm {
    .form-group {
        #ContentEdit_ContentTypeIdSelectBoxIt {
            @include text-overflow;
            padding-right: 20px;
        }
    }
}
// fix SELECT USERNAME popup in chrome;
#modalFindUser {
    .form-contacts {
        overflow: visible;
    }
}

.checkbox-list {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 232px;
    width: 100%;
    z-index: 100;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
}

.checkbox-list-anchor {
    display: block;
    padding: 4px 10px;
    color: #000;
}

.checkbox-list-item {
}


.modal-preferred-add-org {
    @media screen and (min-width: $media_tablet) {
        width: 550px;
    }
}

.modal-fastpay-add-org {
    @media screen and (min-width: $media_tablet) {
        width: 550px;
    }
}

.reportheader {
    color: #3aafda;
    padding: 5px;
}

.report-cell {
    padding: 5px;
}
#eventEditPage .selectboxit-option[data-val="0"] { display: none; }

.profile-form-control-no-overflow {
    overflow: visible;

    .selectboxit-container {
        position: relative;
        display: block;
    }

    .selectboxit-options {
        width: 100%;
    }
}

.form__no-errors-shown {
    .has-error {
        .form-control {
            border-color: #ccc;
            &:focus {
                border-color: #66afe9;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }
}

.break-words {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.mobile-hidden {
    @media screen and (max-width: $media-mobile) {
        display: none !important;
    }
}

table {
    border-collapse: separate;
    .section-summary & {
        border-collapse: collapse;
    }
}

.selectboxit-option-icon-container {
    display: none;
}

.cd-drop-mobile-fix {
    display: none;
    .cd-supports-touchevents & {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        max-width: 100%;
    }

}

#loginForm {
    .input-group {
        display: block;
        overflow: hidden;
    }
    .input-group-addon {
        float: left;
        width: 34px;
        height: 34px;
        line-height: 34px;
        padding: 0;
        text-align: center;
    }

    .form-control {
        float: left;
        width: calc(100% - 34px);
    }
}

.overflow {
    overflow: hidden;
}

.contacts-table {
    margin-bottom: 20px;
}

.control-label.spoiler-link {
    @media screen and (max-width: $media_mobile) {
        float: left;
        margin-right: 15px;
        font-size: 14px;
        width: auto !important;
    }
}

.booking-event-header {
    .form-group {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .control-label {
        height: 25px;
        line-height: 25px;
        font-weight: 500;
    }
    .profile-form-data,
    .profile-form-data-text {
        line-height: 25px;
    }
}

.progress {
    position: relative;
    .progress-meter {
        position: absolute;
        max-width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #31b0d5;
        border-radius: 4px;
    }
}

.booking-review-discard {

    .bootbox-confirm{
        .btn{
            overflow: hidden;
            color: transparent;
            text-transform: none;
            &:before {
                display: block;
            }
        }
        .btn-default:before{
            content: 'Discard';
            color: black;
        }
        .btn-primary:before{
            content: 'Review';
            color: white;
        }
    }
}

.booking-sessioncheck {

    .bootbox-confirm{
        .btn{
            overflow: hidden;
            color: transparent;
            text-transform: none;
            &:before {
                display: block;
            }
        }
        .btn-default:before{
            content: 'Logout';
            color: black;
        }
        .btn-primary:before{
            content: 'OK';
            color: white;
        }
    }
}

.bootbox-alert {
    z-index: 100000001 !important;
}
.bootbox-alert + .modal-backdrop {
    z-index: 100000000 !important;
}

.offset-right-labels .control-label {
    margin-right: 30px;
}

.datepicker.hide-year {
    .datepicker-months thead {
        display: block;
        height: 0;
        visibility: hidden;
        overflow: hidden;
    }
}

.full-width {
    width: 100%;
    max-width: 1000px;
}

//.modal--edit-addendum-dep {
//    .popup-form--edit-addendum {
//        min-width: 770px;
//
//    }
//    .btn-input-tabs {
//        margin-left: 4px;
//    }
//    .field.addendum__rate-date {
//        width: 105px;
//    }
//    .field--addendum-rate-field {
//        width: 395px;
//    }
//    .form-group--table-cell:not(:first-child) {
//        padding-left: 4px;
//    }
//}


.form-control.cursor-help {
    cursor: url('images/cursor-search.png'), auto;
}

.next-year, .prev-year {
    display: none;
    .datepicker-days & {
        display: table-cell;
    }
    .hide-year & {
        display: none;
    }
}

.sidebar-filters-section {
    overflow: hidden;

    &--collapse {
        height: 22px;
    }
}
.commsion-input {
    width: 150px;
    float: left;
    margin-right: 20px;
    background: #fff !important;
    font-weight: bold;
}

.commsion-radio {
    position: relative;
    top: 8px;
}
@media all and (min-width: 941px) {
    td.td-3 div.form-group.cutted-input {
        width: calc(100% - 39px);
    }
}

.profile td.table-summary {

    &-date .form-group.form-group--profile {
        width: calc(100% - 39px);
        display: inline-block;
    }

    &-data .form-group, &-date .form-group {
        margin: 0;
    }
}

button.btn#submitSaveBtn {
    margin-top: 15px;
}

#report-builder-page {
    .btn-input-tabs {
        width: 34px;
        height: 34px;
        padding: 0;
        line-height: 34px;
        text-align: center;
        color: #a0a0a0;
        border: 1px solid #dadada;
        background-color: #fff;
        margin-left: 5px;
        float: right;
        position: relative;
        z-index: 100;
    }

    .form-group__hotel-acc {
        margin-top: 0;
        width: calc(100% - 19px);

        &--mt {
            margin-top: 15px;
        }
    }

    .form-group__hotel-cust {
        width: calc(100% - 19px);
    }

    .field {
        &--xl {
            width: 276px;
        }
    }

    .fieldset-label {
        &--margin-right {
            width: auto;
            margin-right: 10px;
        }
    }

    .option__inner {
        min-height: 30px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        input[type=checkbox] {
            margin: 0 10px 0 0;
            cursor: pointer;
            display: none;

            & ~ label.label2--custom {
                &:before {
                    content: $fa-var-square-o;
                    font-family: FontAwesome;
                    display: inline-block;
                    font-size: 15px;
                    width: 20px;
                }
            }

            &:checked ~ label.label2--custom {
                &:before {
                    content: $fa-var-check-square-o;
                }
            }
        }

        label.label2--custom {
            margin: 0;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
    }

    .option__inner.hello {
        color: red;
    }

    .option__inner.hi {
        color: yellow;
    }

    .field.select-box {
        select {
            -webkit-appearance: searchfield;
            -moz-appearance:    searchfield;
            appearance:         searchfield;
        }
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #000000;
            height: 0;
            width: 0;
            top: 15px;
            right: 12px;
        }
    }
}

.section-contract {
    .fieldset-label {

        &--table {
            display: block;
            height: auto;
            float: none;
            line-height: 25px;
        }

        &--low-line-height {
            line-height: 19px;
            height: auto;
        }
    }

    input[type=radio] {
        margin: 0 0 0 3px;
    }

    .center-block {
        display: inline-block;
        margin-left: 10px;
        margin-top: -6px;
    }
}

.selection-wrapper {
    position: absolute;
    right: 0;
    bottom: -15px;

    & > a {
        margin-left: 15px;
    }
}

.checkbox-list-inner {
    padding-left: 20px;
    border-width: 0;
    list-style: none;

    .option__inner {
        min-height: 20px !important;
    }
}

.msg-paragraph {
    margin: 0;
    height: 35px;

    &:last-child {
        margin-top: -5px;
        height: 20px;
    }
}

.commision-eligibility-select.has-error .help-block{
    display: none;
}

.modal.validation-failed .modal-dialog .modal-content .modal-header {
  background-color: #a94442;
  color: #fff;
  text-align: center;
  line-height: 34px;
  text-transform: uppercase;
  padding: 0;
  border-radius: 4px 4px 0 0;
  background-clip: padding-box;
}

modal.validation-failed .bootbox.modal .modal-dialog .modal-body {
  padding: 20px;
  text-align: center;
}

modal.validation-failed .popup .modal-content .modal-body .bootbox-body {
  padding-bottom: 20px;
}

.table-grouped-row-hover tbody:hover td {
  background: #f5f5f5;
}

.tableFixHead {
  overflow-y: scroll;
  height: 68vh;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
}

#orgMergePage {
    .tab-pane {
        @include clearfix;
    }

    #merge-tab {
        @include clearfix;

        .form-group {
            width: auto;
            position: relative;

            @media screen and (max-width: $media_mobile) {
                margin-bottom: 15px;
                display: table;
                width: 100%;
            }

            .fieldset-label {
                width: 130px;
                float: left;

                @media screen and (max-width: $media_mobile) {
                    float: none;
                }

                @media screen and (max-width: #{$media_tablet - 1px}) {
                    display: block;
                    float: none;
                }
            }

            .profile-form-control {



                @media screen and (min-width: $media_mobile) {
                    display: block;
                }
            }

            .field {
                float: left;

                .form-control {
                    width: 370px;

                    @media screen and (max-width: $media_mobile) {
                        width: 100%;
                    }
                }

                @media screen and (max-width: $media_mobile) {
                    display: inline-block;
                    vertical-align: middle;
                    width: 100%;
                }
            }

            .input-btn {
                @media screen and (max-width: $media_mobile) {
                    vertical-align: bottom;
                    display: table-cell;
                    width: 1%;
                    white-space: nowrap;
                }
            }

            &--mergefrom {
                .input-btn {
                    @media screen and (max-width: $media_mobile) {
                        width: 156px;
                        float: none;
                    }
                }

                .field {
                    @media screen and (max-width: $media_mobile) {
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                    }
                }

                .form-group { // hide +
                    .btn-input-small-absolute {
                        display: none;
                    }

                    &:last-child {
                        .btn-input-small-absolute {
                            display: block;
                        }
                    }
                }
            }
        }

        .btn-holder {
            float: right;
            display: inline-block;

            .btn {
                width: auto;
            }

            @media screen and (max-width: $media_mobile) {
                width: 100%;
                margin-bottom: 15px;
                float: none;

                .btn {
                    width: 100%;
                }
            }

            &--conflict {
                margin-right: 5px;

                .btn {
                }
            }

            &--merge {
                .btn {
                }
            }
        }

        .input-btn-merge-from {
            float: left;

            .btn {
                float: left;
            }
        }

        .mergeFrom {
            float: left;

            .field--my-contact {
                clear: left;
            }

            .form-group {
                .btn-input-small-absolute {
                    display: none;
                }

                &:last-child {
                    .btn-input-small-absolute {
                        display: inline-block;
                    }
                }
            }
        }

        .message {
            //@include text-overflow;
            clear: both;
            width: 100%;
            max-width: 370px;
            color: #a94442;
            display: none;
        }
    }

    .conflict-info {
        display: inline-block;
    }
    //history
    .history-merge-table {
        width: 100%;

        @media screen and (max-width: $media_tablet) {
            margin-top: 15px;
            border-top: 1px solid #f0f0f0;

            .contacts-table-label {
                min-width: 73px;
            }
        }

        .item {
            @media screen and (min-width: $media_tablet) {
                max-width: 130px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;

                &--id {
                    width: 55px;
                }

                &--from {
                    width: 28%;
                }

                &--to {
                    width: 28%;
                }

                &--date {
                    width: 110px;
                    min-width: 110px;
                }

                &--associate {
                    text-align: right;
                    width: 33%; ///
                }

                &--button {
                    text-align: right;
                    width: 70px;
                }
            }
        }
    }
}
///





#brands-search-page, #history-tab, #groupspage, #event-section {
    .profile-form-control {

        &--date {
            @media screen and (min-width: $media_tablet) {
                display: inline;
            }
        }

        @media screen and (min-width: $media_tablet) {
            max-width: none;
        }
    }

    .form-control {
        @media screen and (min-width: $media_tablet) {
            max-width: 370px;
        }
    }

    .form-group {
        .form-control--date {
            width: 100px;
        }

        .fl,
        .fr {
            @media screen and (max-width: #{$media_tablet - 1px}) {
                width: 100%;
                margin-bottom: 5px;
            }
        }

        .fr {
            .control-label {
                padding-right: 8px;
                text-align: left;

                @media screen and (min-width: $media_tablet) {
                    width: 115px;
                    text-align: right;
                }
            }
        }

        &.date-holder {
            margin-bottom: 0;

            @media screen and (min-width: $media_tablet) {
                margin-bottom: 15px;
            }
        }

        &--profile {
            @media screen and (min-width: $media_tablet) {
                max-width: 524px;
            }
        }
    }

    #btnLoadHistory {
        float: right;
        width: auto;
    }

    #btnLoadHistory,
    #btnSearch {
        @media screen and (max-width: #{$media_tablet - 1px}) {
            width: 100%;
        }
    }

    .form-caption {
        margin-bottom: 15px;
    }

    .control-label {
        @media screen and (min-width: $media_desktop) {
            width: 130px;
        }
    }
}

#brands-search-page {
    .form-group {
        &--profile {
            @media screen and (min-width: $media_tablet) {
                max-width: 548px;
            }
        }
    }

    .form-control {
        @media screen and (min-width: $media_tablet) {
            max-width: 369px;
        }
    }
}

/*******************************
            bootstrap
*******************************/

.container{
  max-width: 100%;
}

.btn{
  box-sizing: border-box;
  width: 100%;

  &.disabled,
  &[disabled] {
    cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
  }
}
.btn-primary {
  background-color: #39afdb;
  border-color: #2d8caf;
  text-transform: uppercase;
}
.btn--small {
  width: 34px;
}

.content-block {
  &--pipeline-table {
    overflow: auto;
  }
  &--no-margin {
    margin-top: 0 !important;
  }
  .fixed-table-container,



  .contacts-table{
    border: 0;
    border-radius: 0;
    &--pipeline-table {
      min-width: 2000px;
      overflow-x: auto;
    }


    th, td {
      border-top: 0;
      border-left: 0;
      border-right: 0;

      //line-height: 26px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    td {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      //&.td-action {
      //  line-height: 36px;
      //  @media screen and (min-width: $media_tablet) {
      //    line-height: 26px;
      //  }
      //}
    }

  /*  .contacts-table-cell{
      ///
      display: inline-block;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis
    }
*/

    th .sortable {
      padding-right: 5px;
      cursor: pointer;
    }
  }
}

.table.contacts-table {
  td {
    vertical-align: middle;
    @media screen and (max-width: $media_mobile) {
      padding-right: 0;
      padding-left: 0;
    }
  }
 }


/*******************************
            awesome
*******************************/
.fa-rotate-45 {
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
  -ms-transform:rotate(45deg);
  -o-transform:rotate(45deg);
  transform:rotate(45deg);
}

/*******************************
            inputs
*******************************/
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number]::-webkit-outer-spin-button { -webkit-appearance: none;}


/*******************************
            datepicker
*******************************/
.datepicker {
  z-index: 100000!important;
}

/*******************************
            bootbox
*******************************/
.bootbox-body {
  white-space: pre-line;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fieldset {
  & .find-brand {
    &__search.xmedium.field {
      @media screen and (min-width: $media_tablet) {
        width: 375px;
      }
    }
    &__error {
      @media screen and (min-width: $media_tablet) {
        margin-left: 90px;
      }
    }
  }

}
#knowledge-base{
  .content-block {
    @include clearfix;
    .fieldset {
      &-item {
        &--search{
          .form-group {
            width: 100%;
            margin-bottom: 0;
            .fieldset-label {
              width: 60px;
            }
            .field {
              width: 330px;
              @media screen and (max-width: $media_mobile) {
                width: 100%;
              }
              margin-bottom: 15px;
            }
            .form-buttons{
              .btn{
                @media screen and (max-width: $media_mobile) {
                  width: 100%;
                  margin-bottom: 15px;
                  &:last-child{
                    margin-bottom: 0;
                  }
                }
                &--manage{
                }
                &-info{
                }
              }
            }
          }
        }
        &--questions{
          .form-buttons{
            width: auto;
            margin: 0 auto;
            text-align: center;
            @include clearfix;
            .col{
              float: left;
              width: 50%;
              @media screen and (max-width: $media_mobile) {
                width: 100%;
                margin-bottom: 15px;
                float: none;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
            .btn{
              margin-left: 1%;
              &-success{
                float: none;
                display: inline-block;
                @media screen and (max-width: $media_mobile) {
                  width: 100%;
                  margin-bottom: 15px;
                }
              }
            }
            .fieldset-label{
              font-size: 15px;
              display: inline-block;
              vertical-align: middle;

              float: none;
              width: auto;
              margin-right: 1%;
              @media screen and (max-width: $media_mobile) {
                width: 100%;
                margin-bottom: 15px;
              }
            }
            .search-link{
              background-color: #7ed321;
              border-color: #64a81a;
              @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0) inset, 0 0 0 rgba(0, 0, 0, 0));

              &:hover {
                opacity: 0.7;
              }

              &[disabled],
              &[disabled]:hover {
                cursor: url('images/cursor-not-allowed.png'), url('/Content/themes/Outlook/images/cursor-ie2.cur'), not-allowed;
              }


              float: none;
              display: inline-block;
              vertical-align: middle;
              @media screen and (max-width: $media_mobile) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &--support-list{
      @include clearfix;
      &__item{
        float: left;
        width: calc(50% - 10px);
        &--left{
          margin-right: 10px;
        }
        &--right{
          margin-left: 10px;
        }
        @media screen and (max-width: $media_mobile) {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  .categories{
    .cat-item{
      margin-bottom: 20px;
      .wrap{
        height: 40px;
      }
    }
    .column{
      box-sizing: border-box;
      &--half{
        float: left;
        width: 50%;
        @media screen and (max-width: $media_mobile) {
          width: 100%;
        }
        .link-list{
          &__item{
            color: #000;
            &:hover{
              color: $c_blue_link;
            }
          }
        }
      }
      &--left{
        padding-right: 25px;
        @media screen and (max-width: $media_mobile) {
          padding: 0;
          margin-bottom: 15px;
        }
      }
      &--right{
        padding-left: 25px;
        @media screen and (max-width: $media_mobile) {
          padding: 0;
        }
      }
      .wrap{
        position: relative;
      }
      .link{
        &-list{
          max-height: 40px;
          overflow: hidden;
        }
        &-more{
          display: none;
        }
      }
      h3.title{
        @include text-overflow;
        padding-bottom: 5px;
        margin-bottom: 10px;
        font-size: 16px;
        &--link-color{
          color: $c_blue_link;
        }
        &:first-child{
          margin-top: 0;
        }
        .fa-folder-o{
          padding-left: 1px; // fix safari chrome
        }
      }
      .title-main{
        margin-bottom: 20px;
        .trim{
          display: inline-block;
          @include text-overflow;
          max-width: 100%;
          padding-bottom: 5px;
          vertical-align: top;
        }
        &--link-color{
          color: $c_blue_link;
        }
        font-size: 16px;
        &:first-child{
          margin-top: 0;
        }
        &--article{
          margin: 0 0 5px 0;
          font-size: 14px;
          @include text-overflow;
          .cat-description{
            margin-left: 10px;
            color: #000;
          }
        }
      }
      .subcat-list{
        margin-bottom: 20px;
        &__item{
          margin-top: 0;
          padding-bottom: 3px;
          margin-bottom: 7px;
          font-size: 14px;
          max-height: 15px;
          line-height: 1;
          overflow: hidden;
          &:last-child{
            margin-bottom: 0;
          }
          .description{
            margin-left: 10px;
            color: #000;
          }
        }
      }
      .subcat-article-list{
        &__item{
          .title{
            font-size: 14px;
            margin-top: 0;
            @include text-overflow;
            padding-bottom: 1px;
            margin-bottom: 1px;
            color: $c_blue_link;
          }
          .path{
            @include text-overflow;
            padding-bottom: 1px;
            margin-bottom: 0;
          }
          .cont{
            max-height: 40px;
            overflow: hidden;
          }
          .path,
          .cont{
            margin-left: 17px;
          }
          .link{
            color: #000;
            &:hover{
              color: $c_blue_link;
            }
            &--more{
              color: #000;
            }
          }
        }
      }
      .link-more{
        color: #000;
        &:hover{
          color: $c_blue_link;
        }
      }
    }

    .empty{
      font-size: 12px;
      color: $c_gray;
    }
    .path{
      margin: 0 0 5px;
      font-size: 14px;
      color: $c_gray;
    }
    &.search{
      .title{
        margin-bottom: 0;
      }
    }
  }
  #editor-panel{
    display: none;
  }
  #categories_data{
    border-bottom: 0;
    [data-field="CountOfArticles"]{
      width: 10px;
    }

    .help-active-label {
      padding-right: 10px;
    }

    .td-added{
      text-align: center;
      @media screen and (max-width: $media_mobile) {
        text-align: left;
      }
    }

    .td-create{
      text-align: center;
      color: #000;
    }

    td.th-notvisible.trim{
      color: $c_blue_link;
      .file-attributes{
        display: none;
        color: #000;
        @media screen and (max-width: $media_mobile) {
          display: block;
        }
      }
    }
  }
  #modalHelpForm{
    .popup{
      width: 485px;
      .form-group{
        @media screen and (max-width: $media_maxdesktop) {
          width: 100%;
        }
      }
      .fieldset-label{
        @media screen and (min-width: $media_mobile) {
          width: 80px;
          float: left;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          position: relative;
          margin-top: 0;
        }
        @media screen and (max-width: $media_maxdesktop) {
          font-size: 12px;
          height: auto;
          line-height: normal;
        }
      }
      @media screen and (min-width: $media_maxdesktop) {
        .field.medium{
          width: 364px;
        }
      }
      @media screen and (max-width: $media_maxdesktop) {
        .field{
          width: 100%;
        }
      }
      @media screen and (max-width: $media_mobile) {
        width: 100%;
      }
    }
  }
}
#paymentspage {
  .bookings-status {
    float: left;
    position: relative;
    @media screen and (min-width: $media_tablet) {
      width: 395px;
    }
    @media screen and (max-width: $media_mobile) {
      width: 100%;
    }
  }

  button + .bookings-status {
    @media screen and (max-width: $media_mobile) {
      width: calc(100% - 39px);
    }
  }

  .date-padding {
    margin-bottom: 0;
    @media screen and (min-width: $media_tablet) {
      margin-bottom: 15px;
    }
  }

  .payments-table {
    & .contacts-table-label {
      @media screen and (max-width: #{$media_tablet - 1px}) {
        min-width: 150px !important;
      }
    }
    & .item {

      @media screen and (min-width: $media_tablet) {
        display: none !important;

        max-width: 130px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;

        &--id {
          width: 50px;
        }
        &--status {
          width: 50px;
        }
        &--eventname {
          max-width: 120px;
        }
        &--associate {
        }
        &--account {
          max-width: 100px;
        }
        &--from {
          width: 50px; ///
        }
        &--to {
          width: 50px;
        }

      }
    }
  }

  .form-group--profile {
    @media screen and (min-width: $media_tablet) {
      max-width: 574px;
    }
  }

}
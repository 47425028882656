@mixin background-size ($option) {
	-webkit-background-size: $option;
	   -moz-background-size: $option;
	     -o-background-size: $option;
			background-size: $option;
}

@mixin br ($mt, $mb) {
	&:after, &:before {
		display: block;
		height: 0;
		visibility: hidden;
		content: ".";
	}
	&:after  {
		margin-bottom: $mb;
	}
	&:before {
		margin-top: $mt;
	}
}

@mixin center ($width) {
	width: $width;
	margin: 0 auto;
}

@mixin center-absolute ($width) {
	position: absolute;
	left: 50%;
	width: $width;
	margin-left: -$width / 2;
}

@mixin circle ($option) {
	width: $option;
	height: $option;
	-webkit-border-radius: 50%;
	   -moz-border-radius: 50%;
	        border-radius: 50%;
}

@mixin clearfix () {
	&:after {
		display: block;
		content: ".";
		height: 0;
		clear: both;
		visibility: hidden;
	}
}

@mixin columns ($cols, $gap: 0) {
	-webkit-column-count    :   $cols;
	-webkit-column-gap      :   $gap;
	   -moz-column-count    :   $cols;
	   -moz-column-gap      :   $gap;
			column-count    :   $cols;
			column-gap      :   $gap;
}

@mixin css-triangle ($direction: left, $color: #000, $width: 10px) {
	$opposite: nth((top,right,bottom,left), index((bottom,left,top,right), $direction));
	border: solid $width transparent;
	border-#{$direction}: none;
	border-#{$opposite}: solid $width $color;
}

//left to right
@mixin fade-out-opacity () {
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 56%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(56%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1)));
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
	background: linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
}

//top to bottom
@mixin fade-out-opacity-top () {
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 56%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(linear, top top, top top, color-stop(0%,rgba(255,255,255,0)), color-stop(56%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
    background: linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 56%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
}

@mixin float ($float, $width) {
	float: $float;
	width: $width;
}

@mixin ib ($vertical-align: top) {
	display: inline-block;
	vertical-align: $vertical-align;
}

@mixin middle-valign () {
	width: 0;
	height: 100%;
	@include ib(middle);
}

@mixin hyphens ($mode: auto) {
	word-wrap: 		 break-word;
	-webkit-hyphens: $mode;
	   -moz-hyphens: $mode;
		-ms-hyphens: $mode;
		 -o-hyphens: $mode;
			hyphens: $mode;
}

@mixin opacity ($opacity) {
	  -moz-opacity: $opacity;
	-khtml-opacity: $opacity;
		   opacity: $opacity;
	filter: alpha(opacity=$opacity*100);
}

@mixin placeholder ($color) {
	&::-webkit-input-placeholder {
		color: lighten($color, 50%);
	}
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {color: $color;}
}

@mixin rgba ($color, $alpha, $ie8-flag: true) {
	$rgba: rgba($color, $alpha);
	$ie-hex-str: ie-hex-str($rgba);
	background-color: transparent;
	background-color: $rgba;
	.ie8 & {
		@if ($ie8-flag == true) {
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
		}
	}

}

@mixin set-bg-color($after: false) {
	@each $item in $g-product-color-values {
		$i: index($g-product-color-values, $item);
		@if ($after) {
			&[data-color="#{$item}"] {
                &:after {
                    background: unquote(nth($g-product-color-values, $i));
                }
			}
		} @else {
			&[data-color="#{$item}"] {
				background: unquote(nth($g-product-color-values, $i));
			}
		}
	}
}

@mixin square ($option) {
	width: $option;
	height: $option;
}

@mixin text-overflow () {
	overflow: hidden;
	text-overflow:	ellipsis;
    white-space: nowrap;
}

@mixin text-shadow ($shadow) {
	-webkit-text-shadow:	$shadow;
	-moz-text-shadow:	$shadow;
	text-shadow:	$shadow;
}

@mixin user-select ($user-select: none) {
	   -moz-user-select: $user-select;
	-webkit-user-select: $user-select;
		-ms-user-select: $user-select;
			user-select: $user-select;
}

@mixin user-drag ($user-drag: none) {
	-webkit-user-drag: $user-drag;
			user-drag: $user-drag;
}

@mixin wh ($width, $height) {
	width: $width;
	height: $height;
}

// ============ css-transforms ============
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
     -moz-transform: scale($ratio);
      -ms-transform: scale($ratio);
       -o-transform: scale($ratio);
          transform: scale($ratio);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
     -moz-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y);
       -o-transform: skew($x, $y);
          transform: skew($x, $y);
  -webkit-backface-visibility: hidden;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
     -moz-transform: translate($x, $y);
      -ms-transform: translate($x, $y);
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
     -moz-transform: translate3d($x, $y, $z);
       -o-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}
// ============ (#end) css-transforms ============


// ============ gradients ============
@mixin gradient-vertical ($top,$bottom) {
	background: $top;
	background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom));
	background: -webkit-linear-gradient(top, $top 0%,$bottom 100%);
	background: -o-linear-gradient(top, $top 0%,$bottom 100%);
	background: -ms-linear-gradient(top, $top 0%,$bottom 100%);
	background: linear-gradient(top, $top 0%,$bottom 100%);
	filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$top}', endColorstr='#{$bottom}');
}

@mixin gradient-horizontal ($startColor, $endColor) {
	background-color: $endColor;
	background-image: -moz-linear-gradient(left, $startColor, $endColor);
	background-image: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor));
	background-image: -webkit-linear-gradient(left, $startColor, $endColor);
	background-image: -o-linear-gradient(left, $startColor, $endColor);
	background-image: linear-gradient(to right, $startColor, $endColor);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=1, startColorstr='#{$startColor}', endColorstr='#{$endColor}');
}
// ============ (#end) gradients ============

@mixin centered($direction: 'xy') {
	@if $direction == 'xy' {
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	} @else {
		@if $direction == 'x' {
			position: absolute;
			left: 50%;
			-ms-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}
		@if $direction == 'y' {
			position: absolute;
			top: 50%;
			-ms-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
		}
		@if $direction == '0' {
			top: auto;
			-ms-transform: translate(0);
			-webkit-transform: translate(0);
			transform: translate(0);
		}
	}
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
	-webkit-box-shadow:$top $left $blur $color #{$inset};
	-moz-box-shadow:$top $left $blur $color #{$inset};
	box-shadow:$top $left $blur $color #{$inset};
}

@mixin transition($what: all, $length: 1s, $easing: ease-in-out) {
	-moz-transition:    $what $length $easing;
	-o-transition:      $what $length $easing;
	-webkit-transition: $what $length $easing;
	-ms-transition:     $what $length $easing;
	transition:         $what $length $easing;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin box-sizing($box-model: border-box) {
	-webkit-box-sizing: $box-model; // Safari <= 5
	-moz-box-sizing: $box-model; // Firefox <= 19
	box-sizing: $box-model;
}
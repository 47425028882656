﻿.groupspage {
  .profile-form-control{

    &--date {
      @media screen and (min-width: $media_tablet) {
        display: inline;
      }
    }

    @media screen and (min-width: $media_tablet) {
      max-width: none;
    }
  }
  .form-control{
    @media screen and (min-width: $media_tablet) {
      max-width: 291px;
    }
  }
  .form-group{
    .form-control--date{
      width: 100px;
    }
    .fl,
    .fr{
      @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .fr{
      .control-label{
        padding-right: 8px;
        text-align: left;
        @media screen and (min-width: $media_tablet) {
          width: 115px;
          text-align: right;
        }
        @media screen and (max-width: $media_desktop) {
          text-align: left;
        }
      }
    }
    &.date-holder{
      margin-bottom: 0;
      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }

    }
    &--profile{
      @media screen and (min-width: $media_tablet) {
        max-width: 409px;
      }
      @media screen and (min-width: $media_desktop) {
        max-width: 524px;
      }
    }
  }
  #btnSearch{
    @media screen and (max-width: $media_tablet) {
      width: 100%;
    }
  }
  .form-caption{
    margin-bottom: 15px;
  }
  .control-label{
    @media screen and (min-width: $media_desktop) {
      width: 130px;
    }
  }
}
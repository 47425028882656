﻿#pipeline-associates-select-box,
#pipeline-team-select-box {
	.checkbox {
		margin: 0;
	}
}

.site-header.pdf {
	display: none;

}

.form-group--reports {
	@media screen and (min-width: $media_tablet) {
		padding: 10px;
	}
}

.form-group--reports .reportname-list-item {
	width: 280px;
	padding-top: 20px;

	@media screen and (max-width: $media_mobile) {
		width: 240px;
	}
}

.report-link {
	float: left;
	width: calc(100% - 15px - 8px - 40px);
}

.reports-table {
	width: 100% !important;
	table-layout: fixed;
}

	.reports-table--font-smaller td {
		font-size: 0.8em;
	}

	.reports-table th.len-16mm, .reports-table td.len-16mm {
		width: 16mm;
	}

	.reports-table th.len-20mm, .reports-table td.len-20mm {
		width: 20mm;
	}

	.reports-table th.len-14mm, .reports-table td.len-14mm {
		width: 14mm;
	}

	.reports-table th.len-12mm, .reports-table td.len-12mm {
		width: 12mm;
	}

	.reports-table th.len-24mm, .reports-table td.len-24mm {
		width: 24mm;
	}

	.reports-table th.len-22mm, .reports-table td.len-22mm {
		width: 22mm;
	}

/*******************************
            colors
*******************************/

$c_blue_brand: #1d344f;
$c_green_brand: #436551;

$c_blue: #39afdb;
$c_blue_link: #4a90e3;
$c_blue_light: #ebf3fe;
$c_blue_hover: #0c4182;
$c_green_light: #7ed321;
$c_gray: #979797;
$c_gray_light: #d3d4d7;
$c_gray_base: #f3f3f3;


/*******************************
             vars
*******************************/

$headerHeight: 75px;
$margin: 20px;
$marginInner: 15px;
$paddingInner: 20px;
$radius: 5px;
$sidebarWidth: 255px;
$menuWidth: 90px;
$menuAccountWidth: 150px;
$modalHeaderFooterHeight: 156px;

$media_smallmobile: 480px;
$media_mobile: 940px;
$media_maxdesktop: 1280px;

$media_mediummobile: 641px;
$media_tablet: 941px;
$media_smalldesktop: 1000px;
$media_mediumdesktop: 1200px;

$media_desktop: 1281px;
$media_standartdesktop: 1366px;
$media_largedesktop: 1400px;
$media_xlargedesktop: 1600px;
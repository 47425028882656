#preferred-rules-search-page{

  .profile-form-control{

    &--date {
      @media screen and (min-width: $media_tablet) {
        display: inline;
      }
    }

    @media screen and (min-width: $media_tablet) {
      max-width: none;
    }
  }
  .form-control{
    @media screen and (min-width: $media_tablet) {
      max-width: 394px;
    }

    @media screen and (min-width: $media_desktop) {
      max-width: 379px;
    }
  }
  .form-group{
    .form-control--date{
      width: 100px;
    }
    .fl,
    .fr{
      @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .fr{
      .control-label{
        padding-right: 8px;
        text-align: left;
        @media screen and (min-width: $media_tablet) {
          width: 115px;
          text-align: right;
        }
        @media screen and (max-width: $media_desktop) {
          text-align: left;
        }
      }
    }
    &.date-holder{
      margin-bottom: 0;
      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }

    }
    &--profile{
      @media screen and (min-width: $media_tablet) {
        max-width: 548px;
      }
    }
  }
  #btnLoadHistory{
    float: right;
    width: auto;
  }
  #btnLoadHistory,
  #btnSearch{
    @media screen and (max-width: #{$media_tablet - 1px}) {
      width: 100%;
    }
  }
  .form-caption{
    margin-bottom: 15px;
  }
  .control-label{
    @media screen and (min-width: $media_desktop) {
      width: 130px;
    }
  }
}

#modalNewContract {
  .form-group {
    z-index: 10;
    &--overlap-high {
      z-index: 50;
    }
    &--overlap-medium {
      z-index: 40;
    }
    &--overlap-small {
      z-index: 30;
    }
    &--without-index {
      z-index: auto;
    }
  }
}

.field {
  &--relative {
    position: relative;
  }
}

.help-block {
  &--contracts {
    margin-left: 100px;
  }
}
.fieldset {
  //margin-top: 20px;
  &--popup {
    margin-top: 0;
  }

  @media screen and (min-width: $media_desktop) {
    margin-top: 0;
  }

  .profile-user & {
    margin-top: 0;
  }

  .margin-r > .form-group {
    padding-right: 39px;
    @media screen and (max-width: $media_mobile) {
      padding-right: 0;
    }
  }

  .margin-r-two > .form-group {
    padding-right: 80px;
  }

  .relative {
    position: relative;
  }

  .col {
    width: 49%;

    @media screen and (min-width: $media_desktop) {
      width: auto;
    }
  }

  .col--resources {

    @media screen and (min-width: $media_tablet) {
      width: 50%;
    }

    @media screen and (min-width: $media_desktop) {
      width: auto;
    }

    @media screen and (min-width: $media_standartdesktop) {
      width: 50%;
    }
  }

  &-col--preferredrules {
    @media screen and (min-width: $media_tablet) {
      display: table;
    }
  }

  .col-two-medium {
    width: 49%;

    @media screen and (min-width: $media_tablet) {
      width: 185px;
    }

    @media screen and (min-width: $media_desktop) {
      width: auto;
    }
  }

  .col-two-xsmall {
    width: 49%;

    @media screen and (min-width: $media_tablet) {
      width: 181px;
    }

    @media screen and (min-width: $media_desktop) {
      width: auto;
    }
    &--addendum-rate {
      margin-left: 10px;
      width: 330px;
    }

    &.booking-dropdown-space {
      @media screen and (min-width: $media_desktop) {
        padding-right: 40px;
      }
    }

    &--no-mobile-float {
      @media screen and (max-width: $media_desktop) {
        float: none;
      }
      @media screen and (min-width: $media_tablet ) {
        width: auto !important;
        float: left;
      }
    }
  }

  //.col-three-large {
  //  @media screen and (min-width: $media_tablet) {
  //    width: calc((100/3)*1% - 4px);
  //    margin-bottom: 15px;
  //    &:nth-child(n+2) {
  //      margin-left: 6px;
  //    }
  //  }
  //}

  .col-four-large {
    @media screen and (min-width: $media_tablet) {
      width: 100%;
      margin-bottom: 15px;

      &:nth-child(n+2) {
        margin-left: 5px;
      }

      .fieldset-label {
        width: 100%;
      }

    }
  }

  &-col-float {
    @media screen and (min-width: $media_tablet) {
      width: 49%;
      &--pipeline {
        padding-right: 20%;
      }
    }

    &--left {
      @media screen and (min-width: $media_tablet) {
        float: left;
      }
    }

    &--right {
      @media screen and (min-width: $media_tablet) {
        float: right;
      }
    }
  }

  &-col-my-contact {
    @media screen and (min-width: $media_tablet) {
      width: 33%;
      float: left;
    }

    &--center {
      @media screen and (min-width: $media_tablet) {
        width: 36%;
      }

      @media screen and (min-width: $media_desktop) {
        width: 39%;
      }

      @media screen and (min-width: $media_standartdesktop) {
        width: 41%;
      }

      @media screen and (min-width: $media_xlargedesktop) {
        width: 37%;
      }
      .fieldset-label--my-contact {
        @media screen and (max-width: #{$media_desktop - 1px}) and (min-width: $media_tablet) {
          width: 70px;
        }
      }
    }

    &--left {
      @media screen and (min-width: $media_tablet) {
        width: 42%;
      }

      @media screen and (min-width: $media_desktop) {
        width: 40%;
      }

      @media screen and (min-width: $media_standartdesktop) {
        width: 37%;
      }

      @media screen and (min-width: $media_xlargedesktop) {
        width: 37%;
      }
    }

    &--right {
      &.mob {
        @media screen and (max-width: $media_tablet - 1) {
          width: 100%;
          margin-top: 15px;
        }
      }

      @media screen and (min-width: $media_tablet) {
        width: 22%;
      }

      @media screen and (min-width: $media_desktop) {
        width: 21%;
      }

      @media screen and (min-width: $media_standartdesktop) {
        width: 22%;
      }

      @media screen and (min-width: $media_xlargedesktop) {
        width: 26%;
      }
    }
  }

  .form-group {
    margin-bottom: 15px;
    position: relative;
    display: table;
    width: 100%;

    &.mobile-no-table {
      @media screen and (max-width: $media_mobile) {
        display: block;
      }

      .field {
        @media screen and (max-width: $media_mobile) {
          margin-bottom: 15px;
        }
      }

      .input-btn {
        @media screen and (max-width: $media_mobile) {
          display: block;
          margin-bottom: 15px;
          width: auto;
          float: left;
        }
      }

      .input-btn-select {
        padding-left: 5px;
        @media screen and (max-width: $media_mobile) {
          width: 100%;
          padding-left: 0;
          margin-bottom: 0;
        }
      }

    }

    @media screen and (min-width: $media_tablet) {
      display: table;
      width: auto;
    }

    &-fullwidth {
      @media screen and (min-width: $media_tablet) {
        width: 100%;
        display: flex;
      }
    }

    @media screen and (min-width: $media_desktop) {
      margin-bottom: 15px;
    }


    &--profile {
      @media screen and (min-width: $media_tablet) {
        width: 100%;
      }
    }

    &--cols {

      @media screen and (min-width: $media_tablet) {
        display: table;
        max-width: none;
      }
    }

    &--cols-bookings {
      @media screen and (min-width: $media_tablet) {
        display: block;
        max-width: 389px;
      }

      @media screen and (min-width: $media_desktop) {
        display: table;
        max-width: none;
      }
    }


    &--cols-resources {

      @media screen and (min-width: $media_tablet) {
        display: table;
        max-width: none;
      }

      .input-btn {
        vertical-align: top;

        .fieldset-button {

          @media screen and (max-width: #{$media_tablet - 1px}) {
            margin-top: 14px;
          }
        }
      }
    }

    &--cols-large {
      @media screen and (min-width: $media_tablet) {
        display: block;
        max-width: 537px;
      }

      @media screen and (min-width: $media_desktop) {
        display: table;
        max-width: none;
      }
    }

    &--table-cell {
      @media screen and (min-width: $media_tablet) {
        display: table-cell;
        &:not(:first-child) {
          padding-left: 5px;
        }
      }
    }

    &.no-margin {
      margin-bottom: 0;
    }

    &--affiliation {
      display: block;
    }

    &--my-contact-center {
      @media screen and (min-width: $media_tablet) {
        margin: 0 0 15px;
      }
    }

    &--profile-row {
      width: 100%;
      max-width: 446px;
    }

    &--from.has-error {
      //margin-bottom: 0;
    }

    &--addendum-date {
      width: 142px;
      @media screen and (min-width: $media_mediumdesktop) {
        width: 125px;
      }

      @media screen and (min-width: $media_desktop) {
        width: 142px;
      }
    }
  }

  &-columns {
    .fieldset-col {

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .fieldset-col-offset {
    @media screen and (min-width: $media_tablet) {
      margin-left: 30px;
    }
  }

  .input-btn {
    vertical-align: bottom;
    display: table-cell;
    width: 1%;
    white-space: nowrap;

    @media screen and (min-width: $media_tablet) {
      width: auto;
    }

    @media screen and (min-width: $media_desktop) {
      display: block;
      float: left;
    }

    &--date {
      @media screen and (min-width: $media_tablet) {
        display: table-cell;
        float: none;
        width: 34px;
      }
    }

    &--new-hotel {
      @media screen and (min-width: $media_tablet) {
        float: right;
      }
    }

    &--mobile-right-btn {
      @media screen and (max-width: $media_tablet) {
        vertical-align: top;
      }
    }
  }

  .field-modal-long {
    @media screen and (min-width: $media_tablet) {
      width: 450px !important;
    }
  }

  .field {
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    &.buttons-3 {
      @media screen and (min-width: $media_desktop) {
        width: 226px !important;
      }
    }

    &--with-right-btn {
      @media screen and (max-width: $media_tablet) {
        padding-right: 40px;
      }
    }

    &--mobile-full {
      @media screen and (max-width: $media_tablet) {
        width: 100% !important;
        margin-top: 10px;
        padding-left: 0 !important;
      }
    }

    @media screen and (min-width: $media_desktop) {
      width: 256px;
    }
    @media screen and (min-width: $media_tablet) {
      width: 226px;

      &.field-resp-small {
        @media screen and (max-width: 1350px) {
          width: 195px;
        }
      }

      &.xxsmall {
        width: 90px;
        &.select-box {
          width: 128px;
        }
      }

      &.lsmall {
        width: 100px;
        position: relative;
      }

      &.gsmall {
        width: 130px;
        height: 34px;
      }

      &.msmall {
        width: 212px;
      }

      &.booking-share-table {
        width: 290px;

      }

      &.xsmall {
        width: 142px;

        &--upload {
          //width: 170px;
          width: 250px;
        }
      }

      &.lmedium {
        width: 181px;
      }

      &.phone {
        width: 110px;
      }

      &.email {
        width: 320px;
      }

      &.small-dropdown {
        width: 181px;
        @media screen and (min-width: $media_tablet) {
          width: 181px;
        }
      }

      &.xmedium {
        width: 305px;
      }

      &.advance-dropdown {
        width: 305px;
        position: relative;
      }

      &.medium {
        width: 330px;
      }

      &.collection-datepicker-field {
        width: 290px;
      }

      &-modal-desk-3buttons {
        @media screen and (min-width: $media_desktop) {
          width: 350px !important; //
        }
      }

      &.large {
        width: 370px;
      }

      &.xlarge {
        width: 369px;
      }

      &--right {
        float: right;
        margin-left: 5px;
      }

      &--hotel-contacts {
        width: 164px;
        margin-left: 5px;
      }

      &.date {
        @media screen and (max-width: $media_maxdesktop) {
          overflow: hidden;
          width: auto;
          float: none;
        }

        @media screen and (min-width: $media_desktop) {
          float: left;
        }
      }

      &.date-affilation {
        @media screen and (min-width: $media_tablet) {
          width: 90px;
        }
      }

      &--trip-one {
        margin-right: 39px;
      }

      &.table-xsmall {
        width: 83px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 65px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 83px;
        }
      }

      &.addendum-rate {
        width: 83px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 65px;
        }

        @media screen and (min-width: $media_smalldesktop) {
          width: 95px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 83px;
        }
      }

      &.table-rates-input {
        width: 88px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 70px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 93px;
        }
      }

      &.table-small {
        width: 95px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 80px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 95px;
        }
      }

      &.table-with-button {
        width: 103px;
      }

      &.rates--room-night {
        width: 80px;

        @media screen and (min-width: $media_desktop) {
          width: 95px;
        }
      }

      &.table-medium {
        width: 142px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 125px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 142px;
        }
      }


      &.addendum-field {
        width: 78px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 70px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 85px;
        }

        @media screen and (min-width: $media_standartdesktop) {
          width: 80px;
        }
      }

      &.addendum-dropdown {
        width: 95px;

        @media screen and (min-width: $media_standartdesktop) {
          width: 110px;
        }
      }

      &.table-rates-total {
        width: 105px;
        @media screen and (min-width: $media_mediumdesktop) {
          width: 115px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 120px;
        }
      }

      &.table-addendum-total {
        width: 85px;
        @media screen and (min-width: $media_mediumdesktop) {
          width: 105px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 110px;
        }
      }

      &.table-xmedium {
        width: 172px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 136px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 172px;
        }
      }


      &.table-large {
        width: 194px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 150px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 194px;
        }
      }

      &.rates--type {
        width: 162px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 150px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 194px;
        }
      }

      &.addendum__rate-date {
        width: 95px;
      }

      &.dropdown-rebates {
        width: 330px;

        @media screen and (min-width: $media_mediumdesktop) {
          width: 290px;
        }

        @media screen and (min-width: $media_desktop) {
          width: 330px;
        }
      }

      &.table-xlarge {
        width: 228px;
      }

      &.t-right {
        .form-control {
          text-align: right;
        }
      }

      &--total {
        float: right;
      }

    }

    @media screen and (min-width: $media_mediumdesktop) {
      &.table-small {
        width: 80px;
      }

      &.table-medium {
        width: 125px;
      }

      &.table-large {
        width: 150px;
      }

    }

    @media screen and (min-width: $media_tablet) {
      float: left;

      &.xsmall {
        &--upload {
          width: 170px;
        }
      }

      &.xlarge {
        width: 458px;
      }

      &.table-small {
        width: 95px;
      }


      &.table-medium {
        width: 142px;
      }

      &.table-large {
          width: 194px;
          @media screen and (min-width: $media_mediumdesktop) and (max-width: $media_largedesktop),
          screen and (max-width: #{$media_smalldesktop + 20px}) {
              width: 150px;
          }
      }
    }

    @media screen and (min-width: $media_desktop) {
      &.xlarge {
        width: 498px;
      }
    }

    &--my-contact {

      @media screen and (min-width: $media_tablet) {
        width: 160px;
      }

      @media screen and (min-width: $media_standartdesktop) {
        width: 190px;
      }

      @media screen and (min-width: $media_xlargedesktop) {
        width: 240px;
      }

      &.reset-filter {
        padding: 6px 0px;
        text-align:left
      }
    }

    &--profile {
      max-width: 406px;

      @media screen and (min-width: $media_mediummobile) {
        width: 100%;
        overflow: visible;
        position: relative;
      }
    }

    &--button-column {
      @media screen and (max-width: #{$media_tablet - 1px}) {
        padding-top: 14px;
      }

      @media screen and (min-width: $media_tablet) {
        float: right;
      }

      @media screen and (min-width: $media_desktop) {
        padding-top: 0;
      }
    }

    &.select-box {
      @media screen and (min-width: $media_tablet) {
        position: relative;
      }
    }

    &--dropdown-wrap {
      .selectboxit-container {
        position: relative;
        display: block;
      }
    }

    &--addendum-rate-field {
      width: 256px;
      @media screen and (min-width: $media_mediumdesktop) {
        width: 254px;
      }

      @media screen and (min-width: $media_desktop) {
        width: 256px;
      }

      @media screen and (max-width: $media_tablet) {
        width: 100%;
      }
    }

    &--addendum-dropdown-field {
      width: 290px;
      @media screen and (min-width: $media_mediumdesktop) {
        width: 288px;
      }

      //@media screen and (min-width: $media_mediumdesktop) and (max-width: $media_desktop) {
      //  width: 254px;
      //}

      @media screen and (min-width: $media_desktop) {
        width: 290px;
      }

      @media screen and (max-width: $media_tablet) {
        width: 100%;
      }
    }
  }

  .select {
    &.xsmall {
      width: 90px;
    }

    &--right {
      float: right;
      margin-left: 5px;
    }

    &--left {
      float: left;
      margin-left: 5px;
    }
  }

  textarea {
    height: 60px;
    resize: none;
  }

  &-label {
    color: #000;
    display: block;
    margin-bottom: 0;
    font-size: 10px;

    &--nomin {
      @media screen and (min-width: $media_tablet) {
        width: 115px;
        float: left;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        position: relative;
        margin-top: 0;
      }
    }

    @media screen and (min-width: $media_tablet) {
      width: 115px;
      float: left;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      position: relative;
      margin-top: 0;
    }

    @media screen and (min-width: $media_tablet) {
      &.tiny {
        width: 47px;
      }

      &.xxsmall {
        width: 70px;
      }

      &.xsmall {
        width: 90px;
      }

      &.phone {
        width: 110px;
      }

      &.email {
        width: 320px;
      }

      &.medium {
        width: 140px;
      }

      &.medium-small {
        @media screen and (max-width: #{$media_desktop - 50px}) {
          width: 130px;
        }
      }

      &.large {
        width: 170px;
        &.enlarged {
          width: 180px;
        }
        &.responsive {
            @media screen and (max-width: #{$media_xlargedesktop + 20px}) {
                width: auto;
            }
            @media screen and (max-width: #{$media_largedesktop}) {
                min-width: 90px;
            }
        }
      }

      &.small {
          &.responsive {
              @media screen and (max-width: #{$media_largedesktop}) {
                  width: 80px;
              }
          }
      }

      &.slarge {
        width: 136px;
      }

      &.auto {
        width: auto;
        padding-right: 10px;
      }

      &.booking-share-table {
        text-align: center;
        width: 100px;
        min-width: 100%;
      }

      &.collection-popup {
        width: 152px;
        &--two-lines {
          line-height: 18px;
        }
      }

      &--sidebar {
        width: 84px;
        text-align: left;
      }

      &--center {
        text-align: center;
      }

      &--right {
        text-align: right;
        padding-right: 10px;
      }

      &--left {
        padding-left: 15px;
      }
    }

    &--visible {
      @media screen and (max-width: $media_mobile) {
        display: block;
      }
    }

    &--table {
      @media screen and (min-width: $media_tablet) {
        display: none;
      }
    }

    &--popup {
      @media screen and (min-width: $media_tablet) {
        width: 90px;
        float: left;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        position: relative;
        margin-top: 0;

        &-medium {
          width: 120px;
        }
      }
    }


    &--big {
      @media screen and (min-width: $media_tablet) {
        width: 100px;
        float: left;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        position: relative;
        margin-top: 0;
      }
    }

    &--popup-find {
      @media screen and (min-width: $media_tablet) {
        float: left;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        position: relative;
        margin-top: 0;
        margin-right: 0;
      }

    }

    &--my-contact {
      @media screen and (min-width: $media_tablet) {
        width: 90px;
      }
    }
  }

  &-button {
    &--small {
      background-color: #fff;
      border: 1px solid #dadada;
      color: #a0a0a0;
      float: right;
      height: 34px;
      line-height: 34px;
      margin-left: 5px;
      padding: 0;
      position: relative;
      width: 34px;
      z-index: 100;

      @media screen and (min-width: $media_tablet) {
        float: none;
      }
    }

    &--medium {
      margin-left: 5px;
      padding-left: 0;
      padding-right: 0;
      text-transform: uppercase;
      width: 90px;
    }
    &--flexible {
      margin-left: 5px;
      padding-left: 10px;
      padding-right: 10px;
      text-transform: uppercase;
      width: auto;
    }

    &--absolute {
      bottom: 15px;
      position: absolute;
      right: 0;

      @media screen and (min-width: $media_tablet) {
        //right: -40px;
      }

      @media screen and (min-width: $media_desktop) {
        bottom: 15px;
      }

      &-two {
        right: -40px;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #dadada;
      color: #a0a0a0;
    }

    &.disabled:hover,
    &.disabled:focus,
    &[disabled]:hover,
    &[disabled]:focus
    {
      background-color: transparent;
    }
  }

  &-foreach {
    @media screen and (min-width: $media_tablet) {
      float: left;
    }

    &--popup {
      @media screen and (min-width: $media_tablet) {
        float: none;
      }
    }
    &--popup-floated {
      @media screen and (min-width: $media_tablet) {
        float: left;
      }
    }
  }

  &-caret {
    position: absolute;
    right: 8px;
    top: 10px;
  }

  .help-block {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #a94442;

    &--new-associate {
      @media screen and (min-width: $media_tablet) {
        padding-left: 120px;
      }
    }


    &.medium {
      @media screen and (min-width: $media_mediummobile) {
        width: 330px;
      }
    }
  }

  &-table {
    @media screen and (max-width: $media_mobile) {
      width: 100%;
      table-layout: fixed;
    }

    margin-bottom: 8px;

    &--fullwidth {
      @media screen and (min-width: $media_tablet) {
        width: 100%;
      }
    }

    .form-group-buttons {
      @media screen and (min-width: $media_tablet) {
        min-width: 75px;
      }
    }

    .field {
      @media screen and (min-width: $media_tablet) {
        min-width: 100%;
      }
    }
    .form-group {
      @media screen and (min-width: $media_tablet) {
        width: 100%;
      }
    }

    &-out {
      margin: 0 -20px;

      @media screen and (min-width: $media_tablet) {
        display: table;
        margin: 0;
      }

      .fieldset-button--absolute {
        bottom: auto;
        right: 59px;
        top: -43px;

        @media screen and (min-width: $media_tablet) {
          right: -40px;
          top: -41px;
        }
      }

      &--tall {
        .fieldset-button--absolute {
          right: 81px;

          @media screen and (min-width: $media_tablet) {
            right: 42px;
          }
        }
      }
    }

    thead {
      @media screen and (max-width: $media_mobile) {
        display: none;
      }
    }

    .t-right {
      text-align: right;
      .fieldset-label {
        float: right;
      }
    }

    tr th {
      padding: 0 3px;
    }

    tr td {
      @media screen and (max-width: $media_mobile) {
        display: block;
        width: 100%;
        padding: 7px 20px;
        &:empty {
          padding: 0;
        }
      }
      @media screen and (min-width: $media_tablet) and (max-width: $media_desktop) {
        padding: 7px 2px;
      }

      padding: 7px 3px;
    }

    tr th,
    tr td {
      vertical-align: middle;

      &:first-child {
        @media screen and (min-width: $media_tablet) {
          padding-left: 0;
        }
      }

      &:last-child {
        border-bottom: 2px solid #f0f0f0;

        @media screen and (min-width: $media_tablet) {
          padding-right: 0;
          border-bottom: 0;
        }
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .fieldset-button--small {
      margin-left: 0;

      &.margin {
        margin-left: 6px;

        @media screen and (min-width: $media_tablet) and (max-width: $media_desktop) {
          margin-left: 4px;
        }
      }

      &.mobile-margin {
        margin-left: 0;
        @media screen and (max-width: $media_mobile) {
          margin-left: 6px;
        }
      }

      &.no-float {
        @media screen and (min-width: $media_tablet) {
          float: none;
        }
      }

      &.disabled:hover,
      &.disabled:focus {
        background-color: transparent;
      }
    }

    .fieldset-button--mobile-left {
      @media screen and (max-width: $media_tablet) {
        float: left;
      }
    }

    .fieldset-label {

      &--prefferedrules {
        min-width: 130px;
        margin-right: 8px;
      }

      height: auto;
      line-height: normal;

      &.auto {
        padding-right: 0;
      }

      &.responsive {
          @media screen and (min-width: $media_mediumdesktop),
          screen and (max-width: #{$media_smalldesktop}) and (min-width: $media_tablet) {
              font-size: 11px;
              line-height: 1;
          }
         @media screen and (min-width: $media_largedesktop),
          screen and (max-width: #{$media_smalldesktop + 100px}) and (min-width: $media_tablet) {
              font-size: 12px;
              line-height: 1.2;
          }
         @media screen and (min-width: #{$media_xlargedesktop +21px}) {
              font-size: 14px;
          }
      }
    }

    .total-label {
      display: none;

      @media screen and (min-width: $media_tablet) {
        text-transform: uppercase;
        font-size: 14px;
        height: 34px;
        line-height: 34px;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        float: right;
        padding-right: 6px;
        display: block;
      }
    }

    .total-caption {
      color: #a94442;
      font-weight: 300;
      font-size: 12px;
      line-height: 34px;
      display: block;
      @media screen and (min-width: $media_tablet) {
        float: left;
      }
    }

    .caption {
      color: #ff0000;
      font-weight: 300;
      font-size: 12px;

      @media screen and (min-width: $media_tablet) {
        font-size: 14px;
      }
    }

    .total {
      text-align: right;
      text-transform: uppercase;
    }
  }

  .reg {
    color: #9b9b9b;
  }


  select {
    padding-right: 20px;
  }

  .intl-tel-input {
    display: block;
    position: static;

    .country-list {
      z-index: 1001;
    }

    .flag-container {
      bottom: auto;
      top: auto;
    }

    .selected-flag {
      height: 34px;
    }
  }

  .no-resize {
    resize: none;
  }

  .btn {
    &:focus,
    &:active {
      outline: none;
    }

    &--my-contact {
      margin-bottom: 15px;

      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }
    }
  }

  .btn-primary {
    background-color: #39afdb;
    border-color: #2d8caf;
    text-transform: uppercase;
  }
  .tab-footer {
    .form-buttons {
      .btn {
        @media screen and (max-width: $media_mobile) {
          width: 100%;
        }
      }
    }
  }
  .form-buttons {
    text-align: right;
    width: 100%;
    clear: both;

    .btn {
      width: auto;
      margin-left: 5px;
      &.pull-left {
        margin-left: 0;
      }
    }

    &--wide {
      @media screen and (max-width: $media_tablet) {
        .btn {
          width: 100%;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .filter-reset {
      padding: 6px 0;
      float:left;

      &--medium {
        margin-left:140px;

        @media screen and (max-width: #{$media_tablet - 1px}) {
          margin-left: 0;
        }
      }

      &--middle {
        margin-left:115px;

        @media screen and (max-width: #{$media_tablet - 1px}) {
          margin-left: 0;
        }
      }

      &--big {
        margin-left:140px;

        @media screen and (max-width: #{$media_tablet - 1px}) {
          margin-left: 0;
        }
      }

      &--preferred {
        margin-left: 130px;

        @media screen and (max-width: #{$media_desktop - 1px}) {
          margin-left: 115px;
        }

        @media screen and (max-width: #{$media_tablet - 1px}) {
          margin-left: 0;
        }
      }

      &--xsmall {
        margin-left:90px;

        @media screen and (max-width: #{$media_tablet - 1px}) {
          margin-left:0;
        }
      }
    }
  }

  .btn-primary,
  .btn-info {
    text-transform: uppercase;
  }

  .btn-default {
    &--auto {
      width: auto;
    }
  }
  .float-left {
    float: left;
  }
  .mobile-float-right {
    @media screen and (max-width: $media_mobile) {
      float: right;
    }
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #000;
}
.form-control {
  @media screen and (min-width: $media_tablet) and (max-width: $media_desktop) {
    padding: 6px 8px;
  }
}

.td--date {
  @media screen and (min-width: $media_tablet) {
    width: 150px;
  }
}

.td-two-buttons {
  @media screen and (min-width: $media_tablet) {
    width: 75px;
  }
}
.fieldset .form-group {
  &.mobile-max-width {
    @media screen and (max-width: $media-mobile) {
      display: block;
    }
  }
}

.userGuidLink {
  margin: 12px 0 25px;
  display: block;
  width: 300px;
}

#pipeline-report-page .fieldset-button--small {
  margin-left: 2px;
}

.mt-20 {
  margin-top: 20px;
}

.flex-child-userGuidLink {
  margin: 0 0 10px 10px;
}

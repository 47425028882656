/*******************************
         placeholders
*******************************/

%reset {
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  list-style: none;
  appearance: none;
}

/*******************************
            typo
*******************************/

a{
  color: $c_blue_link;
}


label{
  font-weight: normal;
}

/*******************************
            elements
*******************************/

.avatar{
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid $c_gray_light;
  margin: 0 $marginInner;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    left: 50%;
    position: relative;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  &-icon {
    font-size: 42px;
    line-height: 57px;
  }

  &--main-nav {
    margin: 0 $marginInner 0 0;
  }

  &_img{
    @include centered;
    max-width: 100%;
  }
}

.overflow {
  overflow: hidden;
}
.col-50 {
  width: 50%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}


/*Placeholder*/
::-webkit-input-placeholder { /* WebKit browsers */
  text-align: left;
  color: #999;
}

:-moz-placeholder { /* Mozilla Firefox */
  text-align: left;
  color: #999;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-align: left;
  color: #999;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-align: left;
  color: #999;
}

a:link,
a:visited,
*:focus{
  outline: none;
}

.js-field-error{
  border-color: #a94442;
}
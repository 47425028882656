#bookings-search-page {

  .form-control {
    @media screen and (min-width: $media_tablet) {
    }
  }

  .profile-form-control--date {
    @media screen and (min-width: $media_tablet) {
      float: left;
    }
  }

  .form-group {
    .form-control--date {
      width: 100px;
    }
    .fl,
    .fr {
      @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .fr {
      .control-label {
        padding-right: 8px;
        text-align: left;
        @media screen and (min-width: $media_tablet) {
          width: 115px;
          text-align: right;
        }
        @media screen and (max-width: $media_desktop) {
          text-align: left;
        }
      }
    }
    &.date-holder {
      margin-bottom: 0;
      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }

    }
    &--profile {
      @media screen and (min-width: $media_tablet) {
        max-width: 524px;
      }
    }
  }

  .date-to {
    float: right;
    @media screen and (min-width: $media_desktop) {
      float: left;
      margin-left: 26px;
    }
    @media screen and (max-width: #{$media_tablet - 1px}) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .bookings-status {
    float: left;
    position: relative;
    width: 370px;
    @media screen and (min-width: $media_tablet) {
      width: 345px;
    }
    @media screen and (max-width: #{$media_tablet - 1px}) {
      width: 100%;
    }
  }
}

#event-tab, #booking-tab, #modalEditAddendum, #modalEditHistory, #modalEditAddendumDep {

  .booking-rate-date {
    @media screen and (min-width: $media_tablet) {
      width: 105px;
    }
  }

  .history-rate-date {
    @media screen and (min-width: $media_mediumdesktop) {
      width: 125px;
    }
    @media screen and (min-width: $media_desktop) {
      width: 142px;
    }
  }

  .no-margin-mobile {
    @media screen and (max-width: $media_mobile) {
      margin-bottom: 0;
    }
  }

  .edit-rate__fieldset-table {
    @media screen and (max-width: $media_mobile) {
      padding-bottom: 52px;
    }
  }

  .form-group {
    .form-control--date {
      width: 100px;
    }

    .fl,
    .fr {
      @media screen and (max-width: #{$media_tablet - 1px}) {
        width: 100%;
        margin-bottom: 5px;
      }
    }

    .fr {
      .control-label {
        padding-right: 8px;
        text-align: left;

        @media screen and (min-width: $media_tablet) {
          width: 115px;
          text-align: right;
        }

        @media screen and (max-width: $media_desktop) {
          text-align: left;
        }
      }
    }

    &.date-holder {
      margin-bottom: 0;

      @media screen and (min-width: $media_tablet) {
        margin-bottom: 15px;
      }
    }

    &--profile {
      @media screen and (min-width: $media_tablet) {
        max-width: 409px;
      }

      @media screen and (min-width: $media_desktop) {
        max-width: 524px;
      }
    }
  }
}

#booking-editor-page {
  .contacts-table-label {
    min-width: 155px !important;
  }

  .tab-content {
    padding-top: 40px;
  }
}

.display-none-for-multiple {
  display: none;
}

.form-group__add-one-more-hotel {
  float: right !important;
}

.form-group__hotel-cust {
  margin-bottom: 15px;
}

.form-group__hotel-acc {
  margin-top: 15px;
}

.conference-manager-wrapper {
  .form-group--left {
    float: left;
    margin-right: 50px;

  }
}
